import React, { ReactNode } from 'react';

import { Alert, DialogTitle, SvgIcon, useTheme } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

import { Delete, Edit, Warning } from '@mui/icons-material';

type IconType = 'warning' | 'delete' | 'edit';

export type DialogHeaderProps = {
    title: ReactNode;
    style?: React.CSSProperties;
} & (
    | { iconType?: IconType; icon?: never }
    | { icon?: React.ComponentClass<SvgIconProps>; iconType?: never }
);

export function DialogHeader({ title, iconType, icon, style }: DialogHeaderProps) {
    const iconByType: Record<IconType, any> = {
        warning: Warning,
        delete: Delete,
        edit: Edit,
    };

    const theme = useTheme();
    const styles: Record<IconType, React.CSSProperties> = {
        warning: {
            color: 'orange',
            backgroundColor: 'rgba(255, 165, 0, 0.1)',
        },
        delete: {
            color: theme.palette.error.main,
            backgroundColor: 'rgba(231, 29, 19, 0.1)',
        },
        edit: {
            color: '#1C2342',
            backgroundColor: '#DCEDFC',
        },
    };

    return (
        <DialogTitle
            sx={{
                fontSize: '1.5rem',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <SvgIcon
                component={iconType ? iconByType[iconType] : icon}
                sx={{
                    backgroundColor: '#DCEDFC',
                    borderRadius: '50%',
                    padding: '0.4rem',
                    marginRight: '1rem',
                    ...(iconType ? styles[iconType] : {}),
                    ...style,
                }}
                fontSize='large'
            />
            {title}
        </DialogTitle>
    );
}
