import { queryOptions } from '@tanstack/react-query';
import ms from 'ms';

import apiListAdvertisers, { ListAdvertiserReqParams } from '@api/admin/advertisers/list';
import apiListAffiliateProfiles from '@api/admin/affiliateProfiles/list';
import apiListEvents, { EventsFilter } from '@api/admin/events/list';
import apiListPartnerships, { PartnershipsFilter } from '@api/admin/partnerships/list';
import apiListPrograms from '@api/admin/programs/list';

export const sharedQueryIntervalOptions = {
    staleTime: ms('1m'),
    gcTime: ms('1w'),
};

export const affiliateProfilesListQuery = queryOptions({
    queryKey: ['affiliate-profiles-list'],
    queryFn: () => apiListAffiliateProfiles(['id', 'name']),
    ...sharedQueryIntervalOptions,
});

export const programsListQuery = queryOptions({
    queryKey: ['programs-list'],
    queryFn: () => apiListPrograms(['id', 'name', 'advertiser', 'status']),
    ...sharedQueryIntervalOptions,
});

export const eventsListQuery = (filter: EventsFilter) =>
    queryOptions({
        queryKey: ['events-list', filter],
        queryFn: () => apiListEvents(filter),
        ...sharedQueryIntervalOptions,
    });

export const partnershipsListQuery = (filter: PartnershipsFilter) =>
    queryOptions({
        queryKey: ['partnerships-list', filter],
        queryFn: () => apiListPartnerships(filter),
        ...sharedQueryIntervalOptions,
    });

export const adminAdvertisersListQuery = (params: ListAdvertiserReqParams = {}) =>
    queryOptions({
        queryKey: ['admin', 'advertisers-list', params],
        queryFn: () => apiListAdvertisers(params),
        ...sharedQueryIntervalOptions,
    });
