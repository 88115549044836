import round from 'lodash/round';

import { getLocale, UserType } from '@libs/getSharedVar';
import currencyFormatter from '@libs/lib_currency';

import { ChartItem, ChartItemBase } from '@api/advertiser/programsDashboard/getCardData';

// value of enum are used to build api request
export enum Metric {
    impressions = 'impressions',
    clicks = 'clicks',
    conversionsCPL = 'conversionsCPL',
    conversionsCPA = 'conversionsCPA',
    conversionsTotal = 'conversionsTotal',
    commissions = 'commissions',
    revenue = 'revenue',
    conversionRate = 'conversionRate',
    EPC = 'EPC',
    ROI = 'ROI',
    commissionsAvg = 'commissionsAvg',
    newMRR = 'newMRR',
    MRR = 'MRR',
    salesCommissions = 'salesCommissions',
}

export const monetaryMetrics: Metric[] = [
    Metric.revenue,
    Metric.commissions,
    Metric.commissionsAvg,
    Metric.EPC,
    Metric.newMRR,
    Metric.MRR,
    Metric.salesCommissions,
] as const;
export const valueMetrics: Metric[] = [
    Metric.impressions,
    Metric.clicks,
    Metric.ROI,
    Metric.conversionsCPL,
    Metric.conversionsCPA,
    Metric.conversionsTotal,
] as const;
export const percentMetrics: Metric[] = [Metric.conversionRate] as const;

export const nonAddableMetric: Metric[] = [
    Metric.conversionRate,
    Metric.EPC,
    Metric.ROI,
    Metric.commissionsAvg,
] as const;
export type NonAddableMetric = (typeof nonAddableMetric)[number];
export type AddableMetric = Exclude<Metric, NonAddableMetric>;

export function isAddableMetric(metric: Metric): metric is AddableMetric {
    return !nonAddableMetric.includes(metric as NonAddableMetric);
}

export function isNonAddableMetric(metric: Metric): metric is NonAddableMetric {
    return nonAddableMetric.includes(metric as NonAddableMetric);
}

export const defaultMetric = Metric.commissions;

export const metricsOptions = [
    {
        label: 'dashboard_metrics_option_type_clicks',
        value: Metric.clicks,
    },
    {
        label: 'dashboard_metrics_option_type_impressions',
        value: Metric.impressions,
    },
    {
        label: 'dashboard_metrics_option_type_leads_conversions',
        value: Metric.conversionsCPL,
    },
    {
        label: 'dashboard_metrics_option_type_sales_conversions',
        value: Metric.conversionsCPA,
    },
    {
        label: 'dashboard_metrics_option_type_total_conversions',
        value: Metric.conversionsTotal,
    },
    {
        label: 'dashboard_metrics_option_type_commissions',
        value: Metric.commissions,
    },
    {
        label: 'dashboard_metrics_option_type_revenue',
        value: Metric.revenue,
    },
    {
        label: 'dashboard_metrics_option_type_conversions_rate',
        value: Metric.conversionRate,
    },
    {
        label: 'dashboard_metrics_option_type_eCPC',
        value: Metric.EPC,
    },
    {
        label: 'dashboard_metrics_option_type_ROI',
        value: Metric.ROI,
    },
    {
        label: 'dashboard_metrics_option_type_average_commission',
        value: Metric.commissionsAvg,
    },
] as const;

export function formatMetric(value: number, metric: Metric, currency: string): string {
    if (monetaryMetrics.includes(metric)) {
        return currencyFormatter(currency, value / 100) ?? '';
    }
    if (metric === Metric.ROI) {
        return `${round(value, 2).toLocaleString(getLocale())}`;
    }
    if (metric === Metric.conversionRate) {
        return `${round(value, 2).toLocaleString(getLocale())} %`;
    }
    if (metric === Metric.EPC) {
        return currencyFormatter(currency, value) ?? '';
    }
    return round(value, 2).toLocaleString(getLocale());
}

export function computeAvg<M extends NonAddableMetric>(items: ChartItem<M>[], metric: M): number {
    switch (metric as NonAddableMetric) {
        case Metric.conversionRate: {
            const _items = items as ChartItem<Metric.conversionRate>[];
            const conversions = sumItemsKey(_items, 'conversions');
            const clicks = sumItemsKey(_items, 'clicks');
            if (clicks === 0) return 0;
            return round((conversions / clicks) * 100, 2);
        }
        case Metric.ROI: {
            const _items = items as ChartItem<Metric.ROI>[];
            const revenue = sumItemsKey(_items, 'revenue');
            const commissions = sumItemsKey(_items, 'commissions');
            if (commissions === 0) return 0;
            return round((revenue - commissions) / commissions, 2);
        }
        case Metric.EPC: {
            const _items = items as ChartItem<Metric.EPC>[];
            const commissions = sumItemsKey(_items, 'commissions');
            const clicks = sumItemsKey(_items, 'clicks');
            if (clicks === 0) return 0;
            return round(commissions / clicks, 2);
        }

        case Metric.commissionsAvg: {
            const _items = items as ChartItem<Metric.commissionsAvg>[];
            const commissions = sumItemsKey(_items, 'commissions');
            const conversions = sumItemsKey(_items, 'conversions');
            if (conversions === 0) return 0;
            return round(commissions / conversions, 2);
        }
        default: {
            return 0;
        }
    }
}

function sumItemsKey<Item extends ChartItemBase>(items: Item[], key: keyof Item) {
    return items.reduce((total, item) => {
        const value = item[key];
        if (typeof value === 'number') {
            return total + value;
        } else {
            return total;
        }
    }, 0);
}

export function getDefaultMetric(userType: UserType) {
    return userType === 'advertiser' ? Metric.conversionsTotal : Metric.commissions;
}
