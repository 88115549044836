import { useHistory } from 'react-router-dom';
import { LocationDescriptor } from 'history';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import { ArrowBackIosNew } from '@mui/icons-material';

export function GoBackBtn({
    to,
    onClick,
    isAbsolute,
    ...props
}: { to?: LocationDescriptor; isAbsolute?: boolean } & IconButtonProps) {
    const history = useHistory();

    return (
        <IconButton
            {...props}
            onClick={(...args) => {
                onClick?.(...args);
                to ? history.push(to) : history.goBack();
            }}
            style={{
                ...(isAbsolute && {
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                }),
                ...props.style,
            }}
        >
            <ArrowBackIosNew
                sx={{
                    position: 'relative',
                    left: '-2px', // otherwise the icon don't feel centred
                }}
            />
        </IconButton>
    );
}
