import { isValidPhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { z } from 'zod';

import { countriesList } from './countriesList';
import { currenciesList } from './currenciesList';

export const zPhone = () => {
    return z.string().refine((value) => isValidPhoneNumber(value), 'zod_invalid_phone');
};

export const zTimezone = () => {
    return z.string().refine((value) => {
        return !!moment.tz.zone(value);
    }, 'zod_invalid_timezone');
};

export const zCountry = () => {
    return z.string().refine((value) => {
        return countriesList.includes(value);
    }, 'zod_invalid_country');
};

export const zCurrency = () => {
    return z.string().refine((value) => {
        return currenciesList.includes(value);
    }, 'zod_invalid_currency');
};

export const zWebUrl = ({ max = null }: { max?: number | null } = {}) => {
    let schema = z.string().url();

    if (max !== null) {
        schema = schema.max(max);
    }

    return schema.refine((value) => {
        return value.startsWith('http://') || value.startsWith('https://');
    }, 'zod_invalid_web_url');
};

export const zFloat = () => {
    return z.preprocess((val) => {
        if (val === '' || val === null || (typeof val === 'string' && !/^[0-9,.]+$/.test(val))) {
            return false;
        }
        if (typeof val === 'string') {
            const parsed = parseFloat(val.replace(',', '.'));
            if (isNaN(parsed)) {
                return false;
            }
            return parsed;
        }
        return val;
    }, z.coerce.number());
};

export const zMongoId = () => {
    return z.string().refine((value) => {
        return /^[0-9a-f]{24}$/.test(value);
    }, 'zod_invalid_mongo_id');
};
