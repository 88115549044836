import React from 'react';
import { Trans } from 'react-i18next';

export type Validator<T> = ComplexValidator<T> | BasicValidator<T>;

export type ComplexValidator<T> = (value: T) => {
    isValid: boolean;
    helperText?: React.ReactNode;
};

export type BasicValidator<T> = (value: T) => boolean;

export const GenericHelperText = <Trans i18nKey='required_field' />;

export function getComplexValidatorFromBasic<T>(validator: Validator<T>): ComplexValidator<T> {
    return (value: T) => {
        const result = validator(value);
        if (typeof result !== 'boolean') {
            return result;
        } else {
            return {
                isValid: result,
                helperText: result ? undefined : GenericHelperText,
            };
        }
    };
}

export function isEmpty(str) {
    if (!str) {
        return true;
    }
    return str === '' || /^[ \t]+$/.test(str);
}

export function isOnlyNumber(str) {
    return /[0-9]+/.test(str);
}

export const isNotEmpty = (value: any) => {
    if (value == null) return false;
    return String(value).trim().length > 0;
};

export const isIp = (txt: string) =>
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        txt,
    );

export const isValidHttpUrl = (string: string): boolean => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    if (string.includes('[…]')) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
};

export const onlyTextAndSpaceRegex = /^[a-zA-Z ]*$/;

// RFC 5322 Official Standard, https://emailregex.com/
// eslint-disable-next-line no-useless-escape
export const mailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isEmail = (value: string) => {
    return value.match(mailRegex) !== null;
};

export const nameValidator: ComplexValidator<string> = (value) => {
    if (isEmpty(value)) {
        return {
            isValid: false,
            helperText: <Trans i18nKey='required_field' />,
        };
    } else if (/[0-9\+\/=@#$%&*(){}|~<>;:[\]]/.test(value)) {
        return {
            isValid: false,
            helperText: <Trans i18nKey='generic_field_unauthorized_character' />,
        };
    } else if (value.length > 50) {
        return {
            isValid: false,
            helperText: <Trans i18nKey='generic_field_too_long' />,
        };
    } else {
        return { isValid: true };
    }
};
