import { styled } from '@mui/material/styles';

export const EventViewLayout = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem 1rem',
    marginTop: '2rem',
});

export const Illustration = styled('img')({
    maxHeight: '60vh',
    maxWidth: '100%',
    marginTop: '2rem',
});

export const Title = styled('h1')({
    fontSize: '3rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    textAlign: 'center',
});

export const Subtitle = styled('h2')({
    marginBottom: '2rem',
    marginTop: '0.5rem',
    fontSize: '1.2rem',
    textAlign: 'center',
});

export const Actions = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.5rem',
});

export default EventViewLayout;
