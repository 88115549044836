import { Trans } from 'react-i18next';

import EventViewLayout, { Illustration, Title } from '../../generic/EventView';
import error401Image from './error401.svg';

const UnauthorizedByPermission = () => {
    return (
        <EventViewLayout>
            <Illustration src={error401Image} />
            <Title>
                <Trans i18nKey='unauthorized_view_msg' />
            </Title>
        </EventViewLayout>
    );
};

export default UnauthorizedByPermission;
