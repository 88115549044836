import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ParamsWithAuthProvider } from '@pages/common/SignupPage/common';

import { Alert, Box, Button, DialogActions, DialogContent } from '@mui/material';

import { useLogout } from '@providers/SessionProvider';

import { AccountPreview } from '@components/generic/AccountPreview';
import { LoadingHandler2 } from '@components/generic/LoadingView';
import { DialogNotAuthTitle } from '@components/notAuth/DialogNotAuth';
import { PageNotAuthLayout } from '@components/notAuth/PageNotAuthLayout';
import { GoBackBtn } from '@components/notAuth/PageNotAuthLayout/GoBackBtn';

import { usePromise } from '@hooks/usePromise';

import {
    AuthProvider,
    oAuthRequesterStorage,
    useOauthStore,
    useThirdPartyUserDataStore,
} from '@libs/auth';
import { getDefaultUserType, getLocale } from '@libs/getSharedVar';
import { storeJwt, storeRefreshToken } from '@libs/jwt';

import { Auth } from '@api/common/types';
import { getMyInfo } from '@api/thirdParty/instagram';
import getCurrentUser from '@api/user/getCurrent';
import getTpLinkStatus, { TpLinkStatus } from '@api/user/getTpLinkStatus';
import apiLogin from '@api/user/login';
import { getInstagramAccessToken } from '@api/user/oAuth/getAccessToken';

export default function OAuthCallbackPage() {
    const { t, i18n } = useTranslation();
    const logout = useLogout();

    const code = getOAuthCode();
    const requestInfo = oAuthRequesterStorage.get();
    const isMissingData = code === null || requestInfo === null;
    const error = new URLSearchParams(window.location.search).get('error_description');

    return (
        <PageNotAuthLayout>
            <Helmet>
                <title>{t('oAuthCallbackPage_tabTitle')}</title>
            </Helmet>

            <GoBackBtn isAbsolute onClick={() => logout()} to={`/${i18n.language}/login`} />

            {isMissingData && (
                <DialogContent>
                    <Alert severity='info'>{error ?? t('generic_error')}</Alert>
                </DialogContent>
            )}
            {!isMissingData && <ViewSuccessfulOauthRedirect />}
        </PageNotAuthLayout>
    );
}

function getOAuthCode() {
    return new URLSearchParams(window.location.search).get('code');
}

function ViewSuccessfulOauthRedirect() {
    const { authProvider } = useParams<ParamsWithAuthProvider>();
    const code = getOAuthCode()!;
    const requestInfo = oAuthRequesterStorage.get()!;
    const history = useHistory();
    const saveTpData = useThirdPartyUserDataStore((store) => store.save);
    const requestedAction: 'signup' | 'login' = requestInfo.page.includes('login')
        ? 'login'
        : 'signup';

    const oauthStore = useOauthStore();

    const login = useLogin();

    const [loading, loadData] = usePromise(async () => {
        let thirdPartyAccessToken =
            oauthStore[authProvider]?.code === code ? oauthStore[authProvider]?.accessToken : null;
        if (thirdPartyAccessToken == null) {
            thirdPartyAccessToken = await getInstagramAccessToken(code);
            oauthStore.storeCode(authProvider, code);
            oauthStore.storeAccessToken(authProvider, thirdPartyAccessToken);
        }

        const user = await getMyInfo({ accessToken: thirdPartyAccessToken });
        saveTpData(authProvider, user);

        const authObj: Auth = {
            method: authProvider,
            accessToken: thirdPartyAccessToken,
        };

        const linkResult = await getTpLinkStatus(authObj);

        if (requestedAction === 'signup' && linkResult.isLinked === false) {
            history.push(getTpSignupUrl(requestInfo.page, authProvider));
        } else if (requestedAction === 'login' && linkResult.isLinked) {
            await login(authObj, getLocale(requestInfo.page.split('?')[0]));
        }

        return linkResult;
    });

    useEffect(() => {
        if (loading.status === 'idle') {
            loadData();
        }
    }, [loading]);

    return (
        <Box sx={{ minHeight: '100px', flex: 1 }}>
            <LoadingHandler2 loading={loading}>
                <ViewSpecialTPCases linkStatus={loading.data!} />
            </LoadingHandler2>
        </Box>
    );
}

function ViewSpecialTPCases({ linkStatus }: { linkStatus: TpLinkStatus }) {
    const { t } = useTranslation();
    const history = useHistory();
    const requestInfo = oAuthRequesterStorage.get()!;
    const requestedAction: 'signup' | 'login' = requestInfo.page.includes('login')
        ? 'login'
        : 'signup';

    const { authProvider } = useParams<ParamsWithAuthProvider>();
    const thirdPartyAccessToken = useOauthStore((store) => store[authProvider]!.accessToken!);
    const tpUser = useThirdPartyUserDataStore((store) => store.get(authProvider)!);
    const locale = getLocale(requestInfo.page.split('?')[0]);

    const login = useLogin();
    async function handleLogin() {
        const auth = {
            method: authProvider,
            accessToken: thirdPartyAccessToken,
        };
        await login(auth, locale);
    }

    if (requestedAction === 'login') {
        if (linkStatus.isLinked) {
            return null; // should already have navigated away
        }
        return (
            <>
                <DialogNotAuthTitle>
                    {t('oAuthCallbackPage_unlinkedAccount_title')}
                </DialogNotAuthTitle>
                <DialogContent>
                    <AccountPreview account={tpUser} style={{ marginBottom: '1rem' }} />
                    {t('oAuthCallbackPage_unlinkedAccount_description', {
                        username: tpUser.username,
                    })}{' '}
                    {linkStatus.potentialAssociatedProfiles && (
                        <>
                            <br />
                            <br />
                            <Trans
                                i18nKey='oAuthCallbackPage_unlinkedAccount_noteProfileReferenced'
                                components={{
                                    strong: <strong />,
                                }}
                                count={linkStatus.potentialAssociatedProfiles.length}
                            />
                        </>
                    )}
                    <Box
                        sx={{
                            marginTop: '1.5rem',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                        }}
                    >
                        {linkStatus.potentialAssociatedProfiles &&
                            linkStatus.potentialAssociatedProfiles.map((profile) => (
                                <AccountPreview key={profile.id} account={profile} />
                            ))}
                    </Box>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                // later: handle case of advertiser when tp is allowed for them
                                history.push(`/${locale}/publisher-signup/${authProvider}`);
                            }}
                        >
                            {t('oAuthCallbackPage_unlinkedAccount_action_signup')}
                        </Button>
                        <Button
                            onClick={() => history.push(requestInfo.page)}
                            // must use "requestInfo.page" instead of hard link to /login because
                            // request page may contain query string like the next page to go post login
                            variant='contained'
                        >
                            {linkStatus.potentialAssociatedProfiles
                                ? t('oAuthCallbackPage_unlinkedAccount_action_linkThisAccount', {
                                      count: linkStatus.potentialAssociatedProfiles.length,
                                  })
                                : t('oAuthCallbackPage_unlinkedAccount_action_linkAnAccount')}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </>
        );
    } else {
        if (linkStatus.isLinked === false) {
            return null; // should already have navigated away
        }

        return (
            <>
                <DialogNotAuthTitle>
                    {t('oAuthCallbackPage_accountAlreadyLinked_title')}
                </DialogNotAuthTitle>
                <DialogContent>
                    <Trans
                        i18nKey='oAuthCallbackPage_accountAlreadyLinked_description'
                        components={{
                            br: <br />,
                        }}
                        values={{ username: tpUser.username }}
                    />
                    <AccountPreview account={tpUser} style={{ marginTop: '1.5rem' }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => history.push(requestInfo.page)} variant='text'>
                        {t('generic_signup_button')}
                    </Button>
                    <Button onClick={handleLogin} variant='contained'>
                        {t('generic_login')}
                    </Button>
                </DialogActions>
            </>
        );
    }

    return null;
}

// could reuse
function useLogin() {
    const history = useHistory();
    const { i18n } = useTranslation();

    return async function (auth: Auth, locale = i18n.language) {
        const { accessToken, refreshToken } = await apiLogin({
            auth,
            getRefreshToken: true,
        });
        const user = await getCurrentUser(accessToken);
        const userType = getDefaultUserType(user);
        storeJwt(accessToken, userType);
        if (!CONFIG.displayDemoLogin) {
            storeRefreshToken(refreshToken, userType);
        }
        history.push(`/${locale}`);
    };
}

const getTpSignupUrl = (initialSignupUrl: string, authProvider: AuthProvider) => {
    const [path, query] = initialSignupUrl.split('?');
    return `${path}/${authProvider}?${query ?? ''}`;
};

export function OAuthCallbackMobilePage() {
    const { authProvider } = useParams<ParamsWithAuthProvider>();
    const code = getOAuthCode()!;
    const url = `com.affilae.companion://oauthCallback/${authProvider}?code=${code}`;

    useEffect(() => {
        location.replace(url);
    }, []);

    // display a button to continue to the mobile app
    // because safari don't open the app when using location.replace

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Button variant='contained' onClick={() => location.replace(url)}>
                Continue to mobile app
            </Button>
        </div>
    );
}
