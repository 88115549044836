import { getJwtAdmin } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { checkStatus, getAPISubUrlForAdmin } from '@api/common/utils';

export async function mergeInvoices(
    advertiserId: string,
    programId: string,
    paymentRequestIds: string[],
    currency: string,
    commissionsPaymentsRemarks: string | null | undefined,
    remarks: string | null | undefined,
): Promise<Blob> {
    const res = await fetch(getAPISubUrlForAdmin() + '/paymentRequests/invoices.merge', {
        method: 'POST',
        headers: {
            Authorization: getJwtAdmin(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: paymentRequestIds,
            program: programId,
            advertiser: advertiserId,
            currency: currency,
            commissionsPaymentsRemarks: commissionsPaymentsRemarks,
            remarks: remarks,
        }),
    });
    await checkStatus(res, 200);
    const blob = await res.blob();
    return blob;
}
