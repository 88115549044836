import React, { Suspense } from 'react';

import { LoadingView } from '@components/generic/LoadingView';

export function AppLoggedLayout({
    sideBar,
    page,
    globalModals,
    globalBanners,
}: {
    sideBar: React.ReactNode;
    page: React.ReactNode;
    globalModals?: React.ReactNode;
    globalBanners?: React.ReactNode;
}) {
    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            {sideBar}
            {globalModals}

            <main
                style={{
                    flex: '1',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingRight: '5px',
                }}
            >
                <Suspense fallback={<LoadingView />}>
                    {globalBanners}
                    <div
                        style={{
                            flex: '1',
                            overflowX: 'auto',
                        }}
                    >
                        {page}
                    </div>
                </Suspense>
            </main>
        </div>
    );
}
