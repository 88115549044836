import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import EventViewLayout, { Actions, Illustration, Subtitle, Title } from '../../generic/EventView';
import ServerDown from './ServerDown.svg';

const ServerDownErrorPage = () => {
    const { t } = useTranslation();

    return (
        <EventViewLayout>
            <Helmet>{t('serverErrorPage_title')}</Helmet>
            <Illustration src={ServerDown} style={{ height: '28rem', marginBottom: '2rem' }} />
            <Title>{t('serverErrorPage_title')}</Title>
            <Subtitle>
                <Trans i18nKey='serverErrorPage_description' />
            </Subtitle>
            <Actions>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => window.location.reload()}
                >
                    {t('generic_retry')}
                </Button>
            </Actions>
        </EventViewLayout>
    );
};

export default ServerDownErrorPage;
