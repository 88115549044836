import pkg from '../../package.json';

import { ReactElement } from 'react';
import * as Sentry from '@sentry/react';

import { ErrorFallback, setLastEvent } from '@components/nav/RedirectPages/ErrorPage';

import {
    getCurrentAffiliateProfileId,
    getCurrentPartnershipId,
    getCurrentPaymentRequestId,
    getCurrentProgramId,
    getPage,
    history,
} from '@libs/getSharedVar';

import { getConfigError } from '../AppConfig';

const release = `${pkg.name}@${pkg.version}`;
const infoMessages = [release];
if (__COMMIT_BRANCH__ !== 'master') {
    infoMessages.push(`branch: ${__COMMIT_BRANCH__}`);
    infoMessages.push(`commit: ${__CI_COMMIT_SHORT_SHA__}`);
}
console.info(infoMessages.join('\n'));

Sentry.init({
    dsn: CONFIG.sentry.dsn,
    environment: CONFIG.sentry.env,
    release,
    beforeSend(event) {
        setLastEvent(event);
        return event;
    },
});

const configError = getConfigError();
if (configError) {
    Sentry.captureException(configError);
}

Sentry.configureScope((scope) => {
    scope.setLevel('error');
});
updateSentryScope(window.location.pathname);
history.listen((location) => {
    updateSentryScope(location.pathname);
});
function updateSentryScope(urlPath: string) {
    Sentry.configureScope((scope) => {
        const page = getPage(urlPath);
        scope.setTag('page', page);

        // todo (once routing has evolve): move tag init to component reading these url parameters
        type GetParameter = (urlPath?: string) => string | undefined;
        const tagGetters: [string, GetParameter][] = [
            ['program', getCurrentProgramId],
            ['affiliateProfile', getCurrentAffiliateProfileId],
            ['partnership', getCurrentPartnershipId],
            ['paymentRequest', getCurrentPaymentRequestId],
        ];

        for (const [tagName, getter] of tagGetters) {
            scope.setTag(tagName, getter(urlPath));
        }
    });
}

export function ErrorMonitoringProvider({ children }: { children: ReactElement }) {
    return <Sentry.ErrorBoundary fallback={ErrorFallback}>{children}</Sentry.ErrorBoundary>;
}
