import React, { forwardRef, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete, AutocompleteProps, AutocompleteValue, ChipTypeMap } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TextField, TextFieldProps } from '@components/generic/TextField';

import { bound, mergeProps } from '@libs/bound';
import { MongoId } from '@libs/types';

export type AutoCompleteProps<
    Value,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType,
> = Omit<
    AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    'renderInput'
> & {
    TextFieldProps?: TextFieldProps;
    onlySelect?: boolean;
    customOptions?:
        | string[]
        | {
              label: string;
              value:
                  | { categoryMain: MongoId }
                  | { categoryMain: MongoId; categorySub1: MongoId }
                  | { categoryMain: MongoId; categorySub1: MongoId; categorySub2: MongoId };
          }[];
};

export const AutoComplete = forwardRef(function AutoComplete<
    Value,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(
    {
        TextFieldProps,
        onlySelect = false,
        ...otherProps
    }: AutoCompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    ref,
) {
    return (
        <Autocomplete
            ref={ref}
            popupIcon={<ExpandMoreIcon sx={{ color: '#333333' }} />}
            renderInput={(params) => {
                return (
                    <TextField
                        {...mergeProps(params, TextFieldProps)}
                        {...(onlySelect && {
                            onKeyDown: (e) => {
                                if (onlySelect) {
                                    e.preventDefault();
                                }
                            },
                        })}
                    />
                );
            }}
            disableClearable={onlySelect ? true : otherProps.disableClearable}
            {...otherProps}
        />
    );
});

export type BaseOption<Id = string> = {
    id: Id;
    label: ReactNode;
};

export type AutoCompleteHandyProps<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType,
    Id = string,
    Option extends BaseOption<Id> = BaseOption<Id>,
> = Omit<
    AutoCompleteProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    'value' | 'onChange'
> & {
    value: AutocompleteValue<Id, Multiple, DisableClearable, FreeSolo>;
    onChange?: (id: AutocompleteValue<Id, Multiple, DisableClearable, FreeSolo>) => void;
};

/**
 * An autocomplete that is more handy to use that the classic one.
 * it does:
 * - handle the value as an id in props value and onChange
 * - translate the label, the helperText and option labels
 */
export const AutoCompleteHandy = forwardRef(function AutoCompleteHandy<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
    Id = string,
    Option extends BaseOption<Id> = BaseOption<Id>,
>(
    props: AutoCompleteHandyProps<Multiple, DisableClearable, FreeSolo, ChipComponent, Id, Option>,
    ref,
) {
    const { value, onChange, options, TextFieldProps, multiple } = props;

    const translatedOptions = options.map((option) => ({
        ...option,
        label: option.label,
    }));

    const selectedOption = multiple
        ? translatedOptions.filter((option) => value.includes(option.id))
        : (translatedOptions.find((option) => option.id === value) ?? null);

    return (
        <AutoComplete
            {...props}
            ref={ref}
            TextFieldProps={{
                ...TextFieldProps,
                label: TextFieldProps?.label,
                helperText: TextFieldProps?.helperText,
            }}
            options={translatedOptions}
            value={selectedOption}
            onChange={(event, newValue) => {
                onChange(!multiple ? newValue?.id : newValue?.map((option) => option.id));
            }}
        />
    );
});

export const AutoCompleteHandyControlled = createAutoCompleteControlled(AutoCompleteHandy);

export function createAutoCompleteControlled<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
    Id = string,
    Option extends BaseOption<Id> = BaseOption<Id>,
    Props extends Partial<
        AutoCompleteHandyProps<Multiple, DisableClearable, FreeSolo, ChipComponent, Id, Option>
    > = AutoCompleteHandyProps<Multiple, DisableClearable, FreeSolo, ChipComponent, Id, Option>,
>(AutoComplete: (props: Props) => ReactNode) {
    return function AutoCompleteControlled(props: Omit<Props, 'value'> & { name: string }) {
        const { name, TextFieldProps, onChange: onChangeFromProp } = props;
        const {
            control,
            formState: { errors },
        } = useFormContext();
        return (
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    const { onChange: onChangeFromHookForm, name } = field;

                    const error = TextFieldProps?.error ?? Boolean(errors[name]);
                    const helperText = TextFieldProps?.helperText ?? errors[name]?.message;

                    return (
                        <AutoComplete
                            {...field}
                            {...props}
                            ref={field.ref}
                            TextFieldProps={{
                                ...TextFieldProps,
                                // applying following props conditionally even if they are undefined
                                // to avoid to override them if defined by a bound component
                                ...(error && { error }),
                                ...(helperText && { helperText }),
                            }}
                            onChange={(id) => {
                                onChangeFromHookForm(id);
                                onChangeFromProp?.(id);
                            }}
                        />
                    );
                }}
            />
        );
    };
}

export function boundSelect<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
    Id = string,
    Option extends BaseOption<Id> = BaseOption<Id>,
>(
    useProps: (
        props: AutoCompleteHandyProps<
            Multiple,
            DisableClearable,
            FreeSolo,
            ChipComponent,
            Id,
            Option
        >,
    ) => Partial<
        AutoCompleteHandyProps<Multiple, DisableClearable, FreeSolo, ChipComponent, Id, Option>
    >,
) {
    const Select = bound(AutoCompleteHandy, useProps);
    const SelectControlled = createAutoCompleteControlled(Select);
    return [Select, SelectControlled];
}
