import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Button } from '@mui/material';

import { Actions, EventViewLayout, Illustration, Subtitle, Title } from '../../generic/EventView';
import lost from './lost.svg';

const Page404 = () => {
    const { t, i18n } = useTranslation();
    const params = useParams();

    const Page404Actions = () => {
        if (params.side) {
            return (
                <Button
                    variant='contained'
                    color='primary'
                    component={Link}
                    to={`/${i18n.language}/${params.side}`}
                >
                    {t('generic_goToHome')}
                </Button>
            );
        } else {
            return (
                <>
                    <Button
                        variant='contained'
                        color='primary'
                        component={Link}
                        to={`/${i18n.language}/login`}
                    >
                        {t('generic_goToLogin')}
                    </Button>
                    <Button variant='text' component={Link} to={`/${i18n.language}/signup`}>
                        {t('page404_goToSignupAdvertiser')}
                    </Button>
                    <Button
                        variant='text'
                        component={Link}
                        to={`/${i18n.language}/publisher-signup`}
                    >
                        {t('page404_goToSignupPublisher')}
                    </Button>
                </>
            );
        }
    };

    return (
        <EventViewLayout>
            <Helmet>{t('page404_title')}</Helmet>
            <Illustration src={lost} />
            <Title>{t('page404_title')}</Title>
            <Subtitle>{t('page404_subtitle')}</Subtitle>
            <Actions>
                <Page404Actions />
            </Actions>
        </EventViewLayout>
    );
};

export default Page404;
