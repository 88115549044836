import { ComponentType, ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { advertiserSetPrograms, initCurrentAdvertiser } from '@actions/advertiser';
import { useDashboardStore } from '@pages/common/DashboardPage/hooks/useDashboardStore';
import * as Sentry from '@sentry/react';
import { listUsers, logout } from '@slices/users';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

import {
    getHasAdminTakenAccountControl,
    lastAdminUrlStorageKey,
    useHasTakenOver,
} from '@providers/TakeOverProvider';

import { LoadingHandlerWithRetry } from '@components/generic/LoadingView';
import { useBannerStore } from '@components/nav/GlobalBanners/MissingBankDetailsWarningBanner/useBannerStore';
import usePublisherBankDetailsQuery from '@components/nav/GlobalBanners/MissingBankDetailsWarningBanner/usePublisherBankDetailsQuery';

import { useOldAppState } from '@hooks/useOldAppState';
import { usePromise } from '@hooks/usePromise';

import { affiliateProfilesListQuery, programsListQuery } from '@queries/common';

import { useOauthStore, useThirdPartyUserDataStore } from '@libs/auth';
import { ParamTypes, setCurrentUser, UserType } from '@libs/getSharedVar';
import {
    getUserIdFromToken,
    getUserTypeFromAccessToken,
    removeJwt,
    useAccessToken,
} from '@libs/jwt';
import { useAppDispatch } from '@libs/reduxHooks';
import { MongoId } from '@libs/types';

import fetchCurrentAdvertiserData from '@api/advertiser/fetchCurrentAdvertiserData';
import {
    listCurrencies as listCurrenciesAdvertiser,
    listCurrenciesBySubEntities as listCurrenciesBySubEntitiesAdvertiser,
    listCurrenciesResponse as listCurrenciesResponseAdvertiser,
} from '@api/advertiser/listCurrencies';
import fetchCurrentPublisherData from '@api/publisher/fetchCurrentPublisherData';
import {
    listCurrenciesBySubEntities as listCurrenciesBySubEntitiesPublisher,
    listCurrencies as listCurrenciesPublisher,
    listCurrenciesResponse as listCurrenciesResponsePublisher,
} from '@api/publisher/listCurrencies';
import apiGetCurrentUser from '@api/user/getCurrent';

import { useMfaState } from './MfaStateProvider';

export function UserSessionProvider({ children }: { children: ReactNode }) {
    const oldApp = useOldAppState().app;
    const { side: userType } = useParams<ParamTypes>();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const logout = useLogout();

    const accessToken = useAccessToken(userType);
    if (!accessToken) {
        throw new Error('Need access token to load session');
    }
    const requestedUserId = getUserIdFromToken(accessToken);
    const user = oldApp.state.user;

    const [sessionLoading, loadSession] = usePromise(
        async (accessToken) => {
            let user;
            try {
                user = await apiGetCurrentUser(accessToken);
                setCurrentUser(user);
                moment.tz.setDefault(user.timezone);
                oldApp.setState({ user });
            } catch (error) {
                throw new Error('Fail to load user info');
            }

            try {
                await dispatch(listUsers()).unwrap();
            } catch (error) {
                throw new Error('Fail to load users list');
            }
            return user;
        },
        {
            onSuccess: (user) => {
                Sentry.setUser({
                    id: user.id,
                    email: user.email,
                    username: `${user.firstname} ${user.lastname}`,
                    permissions: user.permissions,
                });
            },
        },
    );

    useEffect(() => {
        loadSession(accessToken);
    }, [accessToken]);

    useEffect(() => {
        return () => {
            Sentry.setUser(null);
        };
    }, []);

    let sessionLoadingFinal = sessionLoading;
    if (user != null && user.id !== requestedUserId) {
        // because else sessionLoading.loading ='success' and it will
        // unwantingly render children while user does not match requested user
        sessionLoadingFinal = {
            status: 'loading',
        };
    }
    return (
        <LoadingHandlerWithRetry
            loading={sessionLoadingFinal}
            onRetry={() => loadSession(accessToken)}
            onCancel={() => logout()}
            cancelLabel={getHasAdminTakenAccountControl() ? t('admin_back') : t('logout')}
        >
            {children}
        </LoadingHandlerWithRetry>
    );
}

export function AdvertiserSessionProvider({ children }: { children: ReactNode }) {
    const oldApp = useOldAppState().app;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const logout = useLogout();

    const user = oldApp.state.user!;
    if (!user.isAdvertiser) {
        throw new Error('User is not an advertiser');
    }

    const [sessionLoading, loadSession] = usePromise(
        async () => {
            try {
                const { currentAdvertiser, advertiserPrograms } =
                    await fetchCurrentAdvertiserData();
                const currencies = await listCurrenciesAdvertiser();
                oldApp.setState({
                    advertiser: currentAdvertiser,
                    programs: advertiserPrograms,
                    currencies,
                });
                dispatch(initCurrentAdvertiser(currentAdvertiser));
                dispatch(advertiserSetPrograms(advertiserPrograms));

                const advertiserProgramsIds = advertiserPrograms.map((program) => program.id);
                const currenciesByProgramArray = await listCurrenciesBySubEntitiesAdvertiser(
                    advertiserProgramsIds,
                    'program',
                );
                const currenciesBySubEntities = currenciesByProgramArray.reduce<{
                    [subEntityId: MongoId]: string[];
                }>((acc, { program, currencies }) => {
                    acc[program] = currencies;
                    return acc;
                }, {});

                oldApp.setState({ currenciesBySubEntities });
                return {
                    advertiser: currentAdvertiser,
                    programs: advertiserPrograms,
                };
            } catch (error) {
                throw new Error('Fail to fetch advertiser data');
            }
        },
        {
            onSuccess: ({ advertiser }) => {
                Sentry.setTag('advertiser', advertiser.id);
            },
        },
    );

    useEffect(() => {
        loadSession();

        return () => {
            Sentry.setTag('advertiser', undefined);
            oldApp.setState({
                advertiser: null,
                programs: null,
                currencies: null,
            });
        };
    }, []);

    return (
        <LoadingHandlerWithRetry
            loading={sessionLoading}
            onRetry={loadSession}
            onCancel={() => logout()}
            cancelLabel={getHasAdminTakenAccountControl() ? t('admin_back') : t('Logout')}
        >
            {children}
        </LoadingHandlerWithRetry>
    );
}

export function PublisherSessionProvider({ children }: { children: ReactNode }) {
    const { t } = useTranslation();
    const oldApp = useOldAppState().app;
    const logout = useLogout();
    const { loadBankDetails } = usePublisherBankDetailsQuery();

    const user = oldApp.state.user!;
    if (!user.isPublisher) {
        throw new Error('User is not a publisher');
    }

    const [sessionLoading, loadSession] = usePromise(
        async () => {
            try {
                const { currentPublisher: publisher, publisherAffiliateProfiles: profiles } =
                    await fetchCurrentPublisherData();
                const currencies = await listCurrenciesPublisher();
                oldApp.setState({
                    publisher,
                    affiliateProfiles: profiles,
                    currencies,
                });

                const currenciesBySubEntities: {
                    [subEntityId: MongoId]: string[];
                } = {};

                const currenciesByProfile = await listCurrenciesBySubEntitiesPublisher({
                    groupBy: 'affiliateProfile',
                });

                (currenciesByProfile as listCurrenciesResponsePublisher[]).forEach((currency) => {
                    currenciesBySubEntities[currency.affiliateProfile] = currency.currencies;
                });

                oldApp.setState({ currenciesBySubEntities });

                const profileIds = profiles.map((p) => p.id);
                await loadBankDetails(profileIds);
                return {
                    publisher: publisher,
                    profiles,
                    currencies,
                };
            } catch (error) {
                throw new Error('Fail to fetch publisher data');
            }
        },
        {
            onSuccess: ({ publisher }) => {
                Sentry.setTag('publisher', publisher.id);
            },
        },
    );

    useEffect(() => {
        loadSession();

        return () => {
            Sentry.setTag('publisher', undefined);
            oldApp.setState({
                publisher: null,
                affiliateProfiles: null,
                currencies: null,
            });
        };
    }, []);

    return (
        <LoadingHandlerWithRetry
            loading={sessionLoading}
            onRetry={loadSession}
            onCancel={() => logout()}
            cancelLabel={getHasAdminTakenAccountControl() ? t('admin_back') : t('Logout')}
        >
            {children}
        </LoadingHandlerWithRetry>
    );
}

export function AdminSessionProvider({ children }: { children: ReactNode }) {
    const oldApp = useOldAppState().app;
    const user = oldApp.state.user!;
    if (!user.isAffilaeAdmin) {
        throw new Error('User is not a admin');
    }

    const queryClient = useQueryClient();
    void queryClient.prefetchQuery(affiliateProfilesListQuery);
    void queryClient.prefetchQuery(programsListQuery);

    return <>{children}</>;
}

const sessionProviderMap: Record<UserType, ComponentType<{ children: ReactNode }>> = {
    admin: AdminSessionProvider,
    advertiser: AdvertiserSessionProvider,
    publisher: PublisherSessionProvider,
};

export function SessionProvider({ children }: { children: ReactNode }) {
    const { side: userType } = useParams<ParamTypes>();
    const UserTypeSessionProvider = useMemo(() => sessionProviderMap[userType], [userType]);
    return (
        <UserSessionProvider>
            <UserTypeSessionProvider>{children}</UserTypeSessionProvider>
        </UserSessionProvider>
    );
}

export function useLogout() {
    const { i18n } = useTranslation();
    const params = useParams<ParamTypes>();
    const { setIsBannerOpen } = useBannerStore();
    const userType = params.side ?? getUserTypeFromAccessToken(); // in case it is called before a route is matched
    const dispatch = useAppDispatch();
    const resetMfa = useMfaState((state) => state.reset);
    const hasTakenOver = useHasTakenOver();
    const history = useHistory();
    const removeAllTpData = useThirdPartyUserDataStore((store) => store.removeAll);
    const resetDashboardStore = useDashboardStore((state) => state.reset);

    const lastAdminUrl = localStorage.getItem(lastAdminUrlStorageKey);

    return (logoutAdmin = false) => {
        const shouldOnlyGoBackToAdmin = hasTakenOver && !logoutAdmin;

        const redirectedUrl = shouldOnlyGoBackToAdmin
            ? (lastAdminUrl ?? `/${i18n.language}/admin`)
            : `/${i18n.language}/login`;

        resetDashboardStore();
        removeJwt(userType);
        moment.tz.setDefault();
        setIsBannerOpen(false);
        dispatch(logout());
        if (!shouldOnlyGoBackToAdmin) {
            removeJwt('admin');
            resetMfa();
        }
        removeAllTpData();
        useOauthStore.getState().clearAll();
        history.push(redirectedUrl);
    };
}
