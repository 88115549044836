import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { FallbackRender } from '@sentry/react';

import { Button } from '@mui/material';

import { emailSupport } from '@libs/getSharedVar';

import EventViewLayout, { Actions, Illustration, Subtitle, Title } from '../../generic/EventView';
import IllustrationError from './crash.svg';

let lastEvent: Event | null = null;

export function setLastEvent(event: Event) {
    lastEvent = event;
}

export const ErrorFallback: FallbackRender = (props) => <ErrorPage {...props} variant='error' />;

const ErrorPage = () => {
    const { t } = useTranslation();
    const eventId = lastEvent?.event_id;

    return (
        <EventViewLayout>
            <Helmet>{t('errorPage_title')}</Helmet>
            <Illustration src={IllustrationError} />
            <Title>{t('errorPage_title')}</Title>
            <Subtitle>
                <Trans i18nKey={'errorPage_description'} values={{ eventId }} />
            </Subtitle>
            <Actions>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => window.location.reload()}
                >
                    {t('generic_retry')}
                </Button>
                <Button href={`mailto:${emailSupport}`}>{t('generic_contact_support')}</Button>
            </Actions>
        </EventViewLayout>
    );
};

export default ErrorPage;
