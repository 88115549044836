import { getCurrentUser } from '@libs/getSharedVar';
import { hasPermission } from '@libs/hasPermission';
import { getJwt } from '@libs/jwt';

import { getAPISubUrlForPublisher } from '../utils';

// todo rename to fetch...
export async function loadRecipientsDataPublisher() {
    const sanPartnerships = await fetchPartnerships();
    const currentUser = getCurrentUser();
    const sanPartnershipsAllowed = sanPartnerships.filter((partnership) => {
        const affiliateProfileId = partnership.affiliateProfile.id;
        const isAllowed = hasPermission(currentUser.permissions, 'messages', affiliateProfileId);
        return isAllowed;
    });
    const dataDictionaries = sanPartnershipsAllowed.reduce(combinedReducer, {});
    const dataArrays = {
        programs: Object.values(dataDictionaries.programs || []),
        users: Object.values(dataDictionaries.users || []),
    };
    return dataArrays;

    function combinedReducer(prev = {}, sanPartnership) {
        return {
            programs: programReducer(prev.programs, sanPartnership),
            users: userReducer(prev.users, sanPartnership),
        };
    }

    function programReducer(prevPrograms = {}, sanPartnership) {
        const nextPrograms = { ...prevPrograms };
        const sanProgram = sanPartnership.program;
        if (nextPrograms[sanProgram.id] == null) {
            nextPrograms[sanProgram.id] = {
                object: 'Program',
                id: sanProgram.id,
                title: sanProgram.name,
                managerId: sanProgram.manager,
                userIds: sanPartnership.users.map((user) => user._id),
            };
        }
        return nextPrograms;
    }

    function userReducer(prevUsers = {}, sanPartnership) {
        function subReducer(prevUsers, sanUser) {
            const nextUsers = { ...prevUsers };
            if (nextUsers[sanUser._id] == null) {
                nextUsers[sanUser._id] = {
                    object: 'User',
                    id: sanUser._id,
                    firstName: sanUser.firstname,
                    lastName: sanUser.lastname,
                };
            }
            return nextUsers;
        }

        return sanPartnership.users.reduce(subReducer, prevUsers);
    }
}

function fetchPartnerships() {
    const url = getAPISubUrlForPublisher() + '/partnerships.list' + '?withDetails=true';
    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.status !== 200) {
                throw new Error(response.status + ' ' + response.statusText);
            }
            return response.json();
        })
        .then((body) => {
            const partnerships = body.partnerships.data;
            return partnerships;
        });
}
export { fetchPartnerships };
