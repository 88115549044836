import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';

import { Button } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

import mailbox from '@components/common/mailbox.svg';
import { Tooltip } from '@components/generic/Tooltip';

import { usePromise } from '@hooks/usePromise';

import { sendConfirmEmail } from '@api/user/sendConfirmEmail';

import EventViewLayout, { Actions, Illustration, Subtitle, Title } from '../../generic/EventView';

const UnauthorizedByEmail = () => {
    const [loading, handleSend] = usePromise(sendConfirmEmail);

    const { t, i18n } = useTranslation();

    return (
        <EventViewLayout>
            <Illustration
                src={mailbox}
                style={{ width: '25rem', height: '25rem', margin: '3rem 0' }}
            />
            <Title>{t('unauthorizedByMailsPage_update_email')}</Title>
            <Subtitle>{t('unauthorizedByMailsPage_description')}</Subtitle>
            <Actions>
                <Button
                    variant='contained'
                    color='primary'
                    component={Link}
                    to={`/${i18n.language}/my-details`}
                >
                    {t('unauthorizedByMailsPage_update_email')}
                </Button>
                <LoadingButton
                    onClick={handleSend}
                    color='secondary'
                    variant='contained'
                    disabled={loading.status === 'loading' || loading.status === 'success'}
                    loading={loading.status === 'loading'}
                    endIcon={
                        (loading.status === 'success' && (
                            <CheckIcon fontSize='inherit' style={{ color: 'green' }} />
                        )) ||
                        (loading.status === 'failure' && (
                            <Tooltip
                                title={
                                    loading.error?.message ??
                                    t('messaging_error_view_basic_msg').toString()
                                }
                            >
                                <ErrorIcon color='error' fontSize='small' />
                            </Tooltip>
                        ))
                    }
                >
                    {loading.status !== 'success'
                        ? t('emailValidation_reSendMail')
                        : t('emailValidation_reSendMail_success')}
                </LoadingButton>
            </Actions>
        </EventViewLayout>
    );
};

export default UnauthorizedByEmail;
