import { InputLabel, InputLabelProps } from '@mui/material';

import BubbleHelper from '@components/generic/BubbleHelper';

export type LabelProps = InputLabelProps & {
    label?: React.ReactNode;
    helperText?: string;
    styleContainer?: React.CSSProperties;
    style?: React.CSSProperties;
};

export default function Label({
    label,
    children,
    required,
    helperText,
    styleContainer,
    style,
}: LabelProps) {
    return (
        <InputLabel
            data-testid='label'
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: label != null ? '0.5rem' : undefined,
                margin: 0,
                color: '#1C2342',
                ...styleContainer,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    color: '#1C2342',
                    fontWeight: '500',
                    minHeight: label != null ? '1.5rem' : undefined, // to not use space if no label
                    ...style,
                }}
            >
                {label}
                {required && (
                    <sup
                        style={{
                            color: 'red',
                            marginLeft: '0.1rem',
                            fontSize: '1.2rem',
                            position: 'relative',
                            top: '-0.2rem',
                        }}
                    >
                        *
                    </sup>
                )}
                {helperText != null && (
                    <BubbleHelper
                        title={helperText}
                        placement='top'
                        sx={{ marginLeft: '0.5rem' }}
                    />
                )}
            </div>
            {children}
        </InputLabel>
    );
}
