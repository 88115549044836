import { useMemo } from 'react';

import { Box, Chip } from '@mui/material';

import CountryFlag from '@components/generic/CountryFlag';
import { BaseOption, boundSelect } from '@components/generic/Selects/AutoComplete';

import { useAppSelector } from '@libs/reduxHooks';

import { Countries } from '@api/common/fetchCountries';

function countryToOptions(country: Countries): BaseOption {
    return {
        id: country.code,
        label: country.name,
    };
}

export const [SelectCountry, SelectCountryControlled] = boundSelect(({ multiple, value }) => {
    const countries = useAppSelector((state) => state.libs.countries.data!);
    const options = useMemo(() => countries.map(countryToOptions), [countries]);

    return {
        options,
        TextFieldProps: {
            ...(multiple !== true && value
                ? {
                      InputProps: {
                          startAdornment: (
                              <CountryFlag
                                  countryCode={value as string}
                                  style={{
                                      marginRight: '0.5rem',
                                      marginLeft: '0.5rem',
                                  }}
                              />
                          ),
                      },
                  }
                : {}),
        },
        renderOption: (props, option) => (
            <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <CountryFlag countryCode={option.id} />
                <span>{option.label}</span>
            </Box>
        ),
        renderTags: (options, getTagProps) => {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    {options.map((option, index) => (
                        <Chip
                            {...getTagProps({ index })}
                            key={option.id}
                            label={
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CountryFlag countryCode={option.id} />
                                    {option.label}
                                </div>
                            }
                        />
                    ))}
                </div>
            );
        },
    };
});
