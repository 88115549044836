import { useEffect } from 'react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { LoadingView } from '@components/generic/LoadingView';
import ServerDownErrorPage from '@components/nav/RedirectPages/ServerDownErrorPage';

import { createAsyncThunkSlice } from '@libs/createAsyncThunkSlice';
import { combineLoadingStatuses, LoadingStatus } from '@libs/LoadingStatus';
import { AppDispatch, RootState, useAppDispatch, useAppSelector } from '@libs/reduxHooks';

import { fetchCategories as apiFetchCategories } from '@api/common/fetchCategories';
import { fetchCountries as apiFetchCountries } from '@api/common/fetchCountries';
import { fetchCountriesStates as apiFetchCountriesStates } from '@api/common/fetchCountriesStates';
import { fetchCurrencies as apiFetchCurrencies } from '@api/common/fetchCurrencies';
import { fetchProfilesTypes as apiFetchProfilesTypes } from '@api/common/fetchProfilesTypes';

export function LibsProvider({ children }: { children: React.JSX.Element }) {
    const dispatch = useAppDispatch();
    const libsLoading = useAppSelector(selectLibsLoadingStatus);

    useEffect(() => {
        dispatch(fetchLibs());
    }, []);

    switch (libsLoading) {
        case 'idle':
        case 'loading':
            return <LoadingView />;

        case 'failure':
            return <ServerDownErrorPage />;

        case 'success':
            return children;
    }
}

//#region redux storage
const reduxPrefix = 'libs';

const fetchCategories = createAsyncThunk(`${reduxPrefix}/fetchCategories`, apiFetchCategories);
const categoriesSlice = createAsyncThunkSlice('categories', fetchCategories);

const fetchProfilesTypes = createAsyncThunk(
    `${reduxPrefix}/fetchProfilesTypes`,
    apiFetchProfilesTypes,
);
const profileTypesSlice = createAsyncThunkSlice('profileTypes', fetchProfilesTypes);

const fetchCurrencies = createAsyncThunk(`${reduxPrefix}/fetchCurrencies`, apiFetchCurrencies);
const currenciesSlice = createAsyncThunkSlice('currencies', fetchCurrencies);

const fetchCountries = createAsyncThunk(`${reduxPrefix}/fetchCountries`, apiFetchCountries);
const countriesSlice = createAsyncThunkSlice('countries', fetchCountries);

const fetchCountriesStates = createAsyncThunk(
    `${reduxPrefix}/fetchCountriesStates`,
    apiFetchCountriesStates,
);
const countriesStatesSlice = createAsyncThunkSlice('countriesStates', fetchCountriesStates);

const fetchLibs = async () => (dispatch: AppDispatch) => {
    return Promise.all(
        [
            fetchCategories,
            fetchProfilesTypes,
            fetchCurrencies,
            fetchCountries,
            fetchCountriesStates,
        ].map((thunk) => dispatch(thunk()).unwrap()),
    );
};

function selectLibsLoadingStatus(state: RootState): LoadingStatus {
    return combineLoadingStatuses(
        state.libs.categories.status,
        state.libs.currencies.status,
        state.libs.profileTypes.status,
        state.libs.countries.status,
        state.libs.countriesStates.status,
    );
}

export const libsReducer = combineReducers({
    categories: categoriesSlice.reducer,
    profileTypes: profileTypesSlice.reducer,
    currencies: currenciesSlice.reducer,
    countries: countriesSlice.reducer,
    countriesStates: countriesStatesSlice.reducer,
});
//#endregion
