import React, { useEffect } from 'react';

import { LoadingView } from '@components/generic/LoadingView';

import { usePromise } from '@hooks/usePromise';

import { UserType, userTypes } from '@libs/getSharedVar';
import {
    getJwtForUserType,
    getRefreshToken,
    hasTokenExpired,
    removeOldTokens,
    removeRefreshToken,
    storeJwt,
    willTokenExpiredSoon,
} from '@libs/jwt';

import apiRefreshAccessToken from '@api/user/refreshToken';

/** Refresh access tokens */
export function RefreshTokenHandler({ children }: { children: JSX.Element }) {
    const [loading, load] = usePromise(async () => {
        await Promise.all(userTypes.map(refreshTokenIfNeeded));

        async function refreshTokenIfNeeded(userType: UserType) {
            const refreshToken = getRefreshToken(userType);
            const hasRefreshToken = refreshToken != null && !hasTokenExpired(refreshToken);

            if (!hasRefreshToken) return;

            const accessToken = getJwtForUserType(userType);
            const hasAccessToken = accessToken != null && !hasTokenExpired(accessToken);

            if (!hasAccessToken || (hasAccessToken && willTokenExpiredSoon(accessToken))) {
                try {
                    const token = await apiRefreshAccessToken(refreshToken);
                    storeJwt(token, userType);
                } catch (error) {
                    removeRefreshToken(userType);
                    throw error;
                }
            }
        }
    });

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        removeOldTokens();
    }, []);

    if (loading.status === 'idle' || loading.status === 'loading') {
        return <LoadingView />;
    } else {
        // it ignore failure. Why?
        // because if it fail there will be no access token, therefore user will
        // be redirected to login if need to access logged app, and for
        // non logged app user don't need access token
        return children;
    }
}
