import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from '../common/utils';
import { User } from './types';

export const rolesSales = ['admin_back_office', 'sales_manager', 'sales'];

export default async function listUsers(searchText?: string): Promise<User[]> {
    const url =
        getAPIBaseUrl() +
        'common/users.list' +
        (searchText != null && searchText !== '' ? `?search=${searchText}` : '');
    const response = await fetch(url, { headers: { Authorization: getJwt() || '' } });
    await checkStatus(response, 200);
    const body = await response.json();
    const users = body;
    return users;
}
