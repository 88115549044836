import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';

import { Button, Dialog, DialogActions, DialogContentText } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

import { DialogHeader } from '@components/generic/DialogHeader';
import { TextField } from '@components/generic/TextField';

import { emailSupport, getCurrentUser, getUserType } from '@libs/getSharedVar';
import { removeAccessToken } from '@libs/jwt';

import { deleteEntity } from '@api/common/deleteEntity';

const DeleteAccountDialog = ({
    hasPaymentRequestsOrRevenues,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
    hasPaymentRequestsOrRevenues?: boolean;
}) => {
    const { t, i18n } = useTranslation();
    const locale = i18n.language;
    const { permissions, email } = getCurrentUser();
    const [emailVerification, setEmailVerification] = useState('');
    const isCorrectEmail = email === emailVerification;
    const isButtonDisabled = !isCorrectEmail || permissions !== 'owner';
    const side = getUserType();

    const deleteMutation = useMutation({
        mutationFn: deleteEntity,
    });

    const handleClose = () => {
        if (deleteMutation.status === 'success') {
            removeAccessToken(side);
            window.location.assign(`${CONFIG.website_url}/${locale}/logiciel-affiliation/`);
        } else {
            onClose();
        }
    };

    return (
        <Dialog
            open={Boolean(open)}
            onClose={handleClose}
            PaperProps={{
                'data-testid': 'deleteDialog',
            }}
        >
            <DialogHeader
                title={t('advertiser_form_dialog_delete_account_title')}
                iconType='delete'
            />
            {(deleteMutation.status === 'idle' || deleteMutation.status === 'pending') && (
                <>
                    <DialogContent>
                        <DialogContentText>
                            {side === 'advertiser' && (
                                <div>{t('advertiser_form_dialog_delete_account_content')}</div>
                            )}

                            {side === 'publisher' && hasPaymentRequestsOrRevenues && (
                                <div>
                                    <div>{t('publisher_form_dialog_delete_account_content')}</div>

                                    <div>
                                        {t(
                                            'publisher_form_dialog_delete_account_content_payments_and_earnings',
                                        )}
                                    </div>

                                    <div>
                                        {t('publisher_form_dialog_deleted_account_content_footer')}
                                    </div>
                                </div>
                            )}

                            {side === 'publisher' && !hasPaymentRequestsOrRevenues && (
                                <div>
                                    <div>{t('publisher_form_dialog_delete_account_content')}</div>

                                    <div>
                                        {t('publisher_form_dialog_deleted_account_content_footer')}
                                    </div>
                                </div>
                            )}
                        </DialogContentText>
                        <TextField
                            value={emailVerification}
                            style={{ marginTop: '1rem' }}
                            placeholder='Email'
                            error={!isCorrectEmail}
                            onChange={(event) => {
                                setEmailVerification(event.target.value);
                            }}
                            data-testid='emailField'
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={deleteMutation.status === 'pending'}
                            color='quiet'
                            onClick={handleClose}
                        >
                            {t('generic_cancel')}
                        </Button>
                        <LoadingButton
                            onClick={() => {
                                deleteMutation.mutate();
                            }}
                            variant='contained'
                            disabled={isButtonDisabled}
                            loading={deleteMutation.status === 'pending'}
                        >
                            {t('generic_delete')}
                        </LoadingButton>
                    </DialogActions>
                </>
            )}

            {deleteMutation.status === 'success' && (
                <>
                    <DialogContent
                        sx={{ display: 'flex', flexDirection: 'column', maxWidth: '500px' }}
                    >
                        <DialogContentText>
                            <span data-testid='deletedAccountMessage'>
                                {t('user_details_form_deleted_account_with_redirection')}
                            </span>
                            <span data-testid='deletedAccountMessageOk'>
                                {t('user_details_form_dialog_deleted_account_with_redirection_ok')}
                            </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' onClick={handleClose}>
                            {t('generic_ok')}
                        </Button>
                    </DialogActions>
                </>
            )}

            {deleteMutation.status === 'error' && (
                <>
                    <DialogContent
                        sx={{ display: 'flex', flexDirection: 'column', maxWidth: '500px' }}
                    >
                        <DialogContentText>
                            {t('advertiser_form_dialog_delete_account_error')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='text' color='quiet' onClick={handleClose}>
                            {t('generic_cancel')}
                        </Button>
                        <Button href={`mailto:${emailSupport}`}>
                            {t('generic_contact_support')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
export default DeleteAccountDialog;
