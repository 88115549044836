import { Advertiser, AdvertiserList } from '@components/admin/Advertisers/interfaces';

import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from '../../common/utils';

export type ListAdvertiserReqParams = {
    subscribed?: boolean;
};

export default async function apiList({
    subscribed = true,
}: ListAdvertiserReqParams = {}): Promise<AdvertiserList> {
    const url = getAPIBaseUrl() + `admin/advertisers.list?subscribed=${subscribed}`;

    const response = await fetch(url, {
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
    });
    await checkStatus(response, 200);
    const body = (await response.json()) as Array<Advertiser>;
    return {
        total: body.length,
        data: body,
    };
}
