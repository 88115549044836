import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

import EventViewLayout, { Actions, Illustration, Subtitle, Title } from '../../generic/EventView';
import IllustrationSubscription from './wallet.svg';

const UnauthorizedBySubscription = () => {
    const { t, i18n } = useTranslation();

    return (
        <EventViewLayout>
            <Illustration src={IllustrationSubscription} />
            <Title>{t('unauthorizedBySubscription_title')}</Title>
            <Subtitle>{t('unauthorizedBySubscription_description')}</Subtitle>
            <Actions>
                <Button
                    variant='contained'
                    color='primary'
                    component={Link}
                    to={`/${i18n.language}/advertiser/billing/subscription`}
                >
                    {t('unauthorizedBySubscription_action')}
                </Button>
            </Actions>
        </EventViewLayout>
    );
};

export default UnauthorizedBySubscription;
