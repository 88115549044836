import makeStyles from '@mui/styles/makeStyles';

import { Theme, Tooltip as TooltipMui, TooltipProps } from '@mui/material';

export const useStylesLight = makeStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#ffffff',
        color: '#223946',
        boxShadow: '0px 3px 32px 0px rgba(0, 0, 0, 0.05)',
        fontSize: '0.875rem',
        padding: '0.5rem 1rem',
        fontWeight: 'normal',
        fontFamily: 'Lato',
    },
    arrow: {
        color: '#ffffff',
    },
}));

// Props Memo
// mandatory : title={} for the text inside tooltip
// most common: placement, disableInteractive, disableHoverListener
export const Tooltip = ({ classes, ...props }: TooltipProps) => {
    const innerClasses = useStylesLight();
    const finalClasses = {
        ...classes,
        ...innerClasses,
    };
    return <TooltipMui id='tooltip' classes={finalClasses} {...props} />;
};
