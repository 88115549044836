import { Route, RouteProps, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { useOldAppState } from '@hooks/useOldAppState';

import { getCurrentSubEntityId, isBlockedByMail, ParamTypes } from '@libs/getSharedVar';
import { hasPermission as getHasPermission } from '@libs/hasPermission';

import { Advertiser } from '@api/advertiser/fetchCurrentAdvertiserData';

import { ErrorFallback } from './RedirectPages/ErrorPage';
import UnauthorizedByEmail from './RedirectPages/UnauthorizedByMailsPage';
import UnauthorizedByPermission from './RedirectPages/UnauthorizedByPermissionPage';
import UnauthorizedBySubscription from './RedirectPages/UnauthorizedBySubscription';

interface PageRouteProps extends RouteProps {
    permission?:
        | Parameters<typeof getHasPermission>[1]
        | [Parameters<typeof getHasPermission>[1], Parameters<typeof getHasPermission>[2]];
    subscriptionOptional?: boolean;
}

/**
 * Extends react-router Route with these functionality
 * * block access if user don't have required permission
 * * block access if advertiser need to update subscription
 */
// inspired by https://reactrouter.com/web/example/auth-workflow
export const PageRoute: React.FC<PageRouteProps> = ({
    permission,
    subscriptionOptional = false,
    children,
    ...routeProps
}) => {
    const params = useParams<ParamTypes>();
    const oldGlobalState = useOldAppState().state;
    const user = oldGlobalState.user!;
    const advertiser = oldGlobalState.advertiser;

    const hasPermission = (function () {
        if (permission == null) {
            return true;
        }

        if (Array.isArray(permission)) {
            const [permissionName, subEntityId] = permission;

            return getHasPermission(user, permissionName, subEntityId ?? getCurrentSubEntityId());
        } else {
            return getHasPermission(user, permission);
        }
    })();

    const accessBlockedBySubscription =
        !subscriptionOptional &&
        params.side === 'advertiser' &&
        advertiser != null &&
        hasAccessBlockedBySubscription(advertiser);

    let displayedPage = children;

    if (!hasPermission) {
        displayedPage = <UnauthorizedByPermission />;
    } else if (
        isBlockedByMail(user) &&
        !(typeof routeProps.path === 'string' && routeProps.path.endsWith('/my-details'))
    ) {
        displayedPage = <UnauthorizedByEmail />;
    } else if (params.side === 'advertiser' && advertiser != null && accessBlockedBySubscription) {
        displayedPage = <UnauthorizedBySubscription />;
    }

    const pageName = routeProps.path;

    return (
        <Route {...routeProps}>
            <Sentry.ErrorBoundary
                key={pageName} // a trick to force mount/unmount by page, otherwise it doesn't
                // we use pageName instead of location.pathname because the latter change with locale
                fallback={ErrorFallback}
            >
                {displayedPage}
            </Sentry.ErrorBoundary>
        </Route>
    );
};

function hasAccessBlockedBySubscription(advertiser: Advertiser) {
    const { isFree, status, paymentMethod } = advertiser;
    if (isFree || paymentMethod === 'bankwire') {
        return false;
    }
    return ['frozen', 'cancelled', 'freetrialEnded', 'subscribedEnded'].includes(status);
}
