import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import { Box, FormControl, IconButton, MenuItem, OutlinedInput, TextField } from '@mui/material';
import Select from '@mui/material/Select';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';

import { DateRangePickerRef, PeriodRef } from '@components/generic/DateRangePicker';

import { useAppSelector } from '@libs/reduxHooks';

interface AffiliateProfileAmFiltersProps {
    searchText: string;
    selectedCertifiedOption: boolean | null;
    selectedMarketplaceOption: boolean | null;
    selectedCategories: string[];
    selectedCountries: string[];
    selectedProfileTypes: string[];
    setSelectedProfileTypes: (types: string[]) => void;
    setSelectedCategories: (categories: string[]) => void;
    setSelectedCountries: (coutries: string[]) => void;
    setSelectedMarketplaceOption: (marketplaceOption: boolean) => void;
    setSelectedCertifiedOption: (certfiedOption: boolean) => void;
    setSearchText: (setSearchText: string) => void;
    period: PeriodRef;
    setPeriod: (period: PeriodRef) => void;
}

type FilterItem = {
    value: string | number;
    label: string;
};
export const AffiliateProfileAmFilters = (props: AffiliateProfileAmFiltersProps) => {
    const {
        searchText,
        selectedMarketplaceOption,
        selectedCertifiedOption,
        selectedCategories,
        selectedProfileTypes,
        selectedCountries,
        setSelectedProfileTypes,
        setSelectedCountries,
        setSelectedCategories,
        setSelectedMarketplaceOption,
        setSelectedCertifiedOption,
        setSearchText,
        period,
        setPeriod,
    } = props;
    const { t, i18n } = useTranslation();
    const [currentSearchText, setCurrentSearchText] = useState<string>(searchText);
    const allCategories = useAppSelector((state) => state.libs.categories.data!);
    const allCountries = useAppSelector((state) => state.libs.countries.data!);
    const allTypes = useAppSelector((state) => state.libs.profileTypes.data!);

    const debouncedSearch = useCallback(
        debounce((searchText) => {
            setSearchText(searchText);
        }, 300),
        [],
    );
    const filtersMulti = [
        {
            id: 'profile_type',
            label: t('affiliate_form_type'),
            value: selectedProfileTypes,
            isMulti: true,
            onChange: onSelectProfileTypeOptionChange,
            items: allTypes.map((type) => {
                return { value: type.slug, label: type[`title_${i18n.language}`] };
            }) as FilterItem[],
        },
        {
            id: 'categories',
            label: t('admin_am_column_categories'),
            isMulti: true,
            value: selectedCategories,
            onChange: onSelectCategoriesOptionChange,
            items: allCategories.map((category) => {
                return { value: category.slug_en, label: category[`title_${i18n.language}`] };
            }) as FilterItem[],
        },
        {
            id: 'coutries',
            label: t('admin_am_column_countries'),
            isMulti: true,
            value: selectedCountries,
            onChange: onSelectCountriesOptionChange,
            items: allCountries
                .map((country) => ({
                    value: country.code,
                    label: country.name,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)) as FilterItem[],
        },
    ];
    const filtersSimple = [
        {
            id: 'marketplace',
            label: t('marketplace_filter_label'),
            isMulti: false,
            value: selectedMarketplaceOption,
            onChange: onSelectMarketPlaceOptionChange,
            items: [
                { value: 1, label: t('generic_yes') },
                { value: 0, label: t('generic_no') },
            ] as FilterItem[],
        },
        {
            id: 'certified',
            label: t('certified_filter_label'),
            isMulti: false,
            value: selectedCertifiedOption,
            onChange: onSelectCertifiedOptionChange,
            items: [
                { value: 1, label: t('generic_yes') },
                { value: 0, label: t('generic_no') },
            ] as FilterItem[],
        },
    ];
    function onSelectProfileTypeOptionChange(e: string[]) {
        setSelectedProfileTypes(e);
    }
    function onSelectCategoriesOptionChange(e: string[]) {
        setSelectedCategories(e);
    }
    function onSelectMarketPlaceOptionChange(e: boolean | null) {
        setSelectedMarketplaceOption(e);
    }
    function onSelectCertifiedOptionChange(e: boolean | null) {
        setSelectedCertifiedOption(e);
    }
    function onSelectCountriesOptionChange(e: string[]) {
        setSelectedCountries(e);
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '40px',
                flexWrap: 'wrap',
                gap: '1rem',
            }}
        >
            {filtersMulti.map((filter) => {
                return (
                    <div key={filter.id}>
                        <Select
                            multiple
                            key={filter.id}
                            IconComponent={filter.value.length > 0 ? EmptyIcon : ArrowDropDownIcon}
                            endAdornment={
                                filter.value.length > 0 && (
                                    <IconButton
                                        title={t('generic_clear')}
                                        onClick={() => filter.onChange([])}
                                    >
                                        <CloseIcon onClick={() => filter.onChange([])} />
                                    </IconButton>
                                )
                            }
                            displayEmpty
                            value={filter.value}
                            onChange={(e) => filter.onChange(e.target.value)}
                            input={<OutlinedInput label={filter.label} />}
                            renderValue={() => {
                                return filter.value.length === 0 ? (
                                    <span>{filter.label}</span>
                                ) : (
                                    filter.value.map((val) => {
                                        const item = filter.items.find(
                                            (item: FilterItem) => item.value === val,
                                        );
                                        if (!item) {
                                            return null;
                                        }
                                        return (
                                            <span key={val}>
                                                {item.label + (filter.value.length > 1 ? ', ' : '')}
                                            </span>
                                        );
                                    })
                                );
                            }}
                            sx={styleLightSelect}
                            variant='standard'
                        >
                            {filter.items.map((item: FilterItem) => {
                                return (
                                    <MenuItem
                                        style={{ fontSize: '14px' }}
                                        key={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                );
            })}
            {filtersSimple.map((filter) => {
                return (
                    <div key={filter.id}>
                        <Select
                            key={filter.id}
                            IconComponent={filter.value !== null ? EmptyIcon : ArrowDropDownIcon}
                            endAdornment={
                                filter.value !== null ? (
                                    <IconButton
                                        title={t('generic_clear')}
                                        onClick={() => filter.onChange(null)}
                                    >
                                        <CloseIcon onClick={() => filter.onChange(null)} />
                                    </IconButton>
                                ) : (
                                    <ArrowDropDownIcon />
                                )
                            }
                            displayEmpty
                            value={filter.value}
                            onChange={(e) => filter.onChange(e.target.value)}
                            input={<OutlinedInput label={filter.label} />}
                            renderValue={() => {
                                return (
                                    <span>
                                        {filter.items.find(
                                            (item: FilterItem) => item.value === filter.value,
                                        )?.label ?? filter.label}
                                    </span>
                                );
                            }}
                            sx={styleLightSelect}
                            variant='standard'
                        >
                            {filter.items.map((item: FilterItem) => {
                                return (
                                    <MenuItem
                                        style={{ fontSize: '14px' }}
                                        key={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                );
            })}
            <TextField
                value={currentSearchText}
                data-testid='searchField'
                id='searchField'
                name='searchText'
                sx={styleLightTextfield}
                placeholder={t('am_page_filter_search_placeholder')}
                onChange={(e) => {
                    const value = e.target.value;
                    setCurrentSearchText(value);
                    debouncedSearch(value);
                }}
                InputProps={{ disableUnderline: true }}
            />
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <DateRangePickerRef
                        value={period}
                        onChange={(e) => setPeriod(e)}
                        variant='light'
                    />
                </FormControl>
            </Box>
        </div>
    );
};
const EmptyIcon = () => {
    return <div></div>;
};

export const styleLightInput = {
    height: '44px',
    width: '250px',
    boxShadow: '0px 3px 32px 0px #0000000d',
    borderRadius: '5px',
};

export const styleLightSelect = {
    ...styleLightInput,
    padding: 0,
    fontSize: '14px',
    color: 'grey',

    '.MuiOutlinedInput-notchedOutline': { border: 'none' },
    ':hover': {
        border: 'none',
    },
    '::after': {
        borderBottom: 'none',
    },
    '.MuiOutlinedInput': {
        '&-input:focus': {
            backgroundColor: 'transparent',
        },
    },
};

export const styleLightTextfield = {
    ...styleLightInput,
    '.MuiInput-input': {
        padding: '0 10px',
        margin: 0,
        height: '44px',
    },
};
