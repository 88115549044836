import { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

import { getComplexValidatorFromBasic, isNotEmpty, Validator } from '@libs/validators';

interface FieldOptions<T> {
    validator?: Validator<T>;
    formatter?: (value: T) => T;
    formatterDebounced?: (value: T) => T;
    getShowValidity?: GetShowFieldValidityExtended;
    hasSubmit?: boolean;
}

type GetShowFieldValidityExtended = GetShowFieldValidity | GetShowFieldValiditySimplified;
type GetShowFieldValiditySimplified = 'afterSubmit' | 'afterEdit';

type GetShowFieldValidity = (args: {
    isValid: boolean;
    hasChange: boolean;
    isTyping: boolean;
    hasSubmit: boolean;
}) => boolean;

/** An useState wrapper that add to returning array variable 'isValid' */
export function useField<T>(
    defaultValue: T,
    { validator = isNotEmpty }: { validator?: Validator<T> } = {},
) {
    const [value, setValue] = useState<T>(defaultValue);
    const complexValidator = useMemo(() => getComplexValidatorFromBasic(validator), [validator]);
    const { isValid, helperText } = useMemo(
        () => complexValidator(value),
        [value, complexValidator],
    );
    return { value, setValue, isValid, helperText } as const;
}

export type BasicFieldState<T> = { value: T; setValue: (value: T) => void };

export type TextFieldState = ReturnType<typeof useTextField>;

export function useTextField(
    defaultValue?: string,
    {
        validator = isNotEmpty,
        formatter = (e) => e,
        formatterDebounced = (e: string) => e.trim(),
        getShowValidity: getShowValidityExtended = 'afterEdit',
        hasSubmit = false,
    }: FieldOptions<string> = {},
) {
    const [value, _setValue] = useState(defaultValue ?? '');
    const complexValidator = useMemo(() => getComplexValidatorFromBasic(validator), [validator]);
    const { isValid, helperText } = useMemo(
        () => complexValidator(value),
        [value, complexValidator],
    );

    const [isTyping, setIsTyping] = useState(false);
    const stopTypingDebounced = useCallback(
        debounce((value) => {
            //eslint-disable-line
            setIsTyping(false);
            _setValue(formatter(formatterDebounced(value)));
        }, 700),
        [],
    );
    const [hasChange, setHasChange] = useState(false);
    const getShowValidity = GetShowFieldValidityConverter(getShowValidityExtended);
    const shouldDisplayValidity = getShowValidity({
        isValid,
        hasChange,
        isTyping,
        hasSubmit,
    });

    const setValue = useCallback(
        (value: string) => {
            if (!hasChange) setHasChange(true);
            if (!isTyping) setIsTyping(true);
            stopTypingDebounced(value);
            return _setValue(formatter(value));
        },
        [formatter, hasChange, isTyping, stopTypingDebounced],
    );

    const reset = () => {
        setHasChange(false);
        setIsTyping(false);
        _setValue('');
    };

    return {
        value,
        setValue,
        isValid,
        validator: complexValidator,
        helperText,
        hasChange,
        isTyping,
        shouldDisplayValidity,
        reset,
    };
}

function GetShowFieldValidityConverter(
    getShowFieldValidity: GetShowFieldValidityExtended,
): GetShowFieldValidity {
    if (typeof getShowFieldValidity === 'function') {
        return getShowFieldValidity;
    }

    switch (getShowFieldValidity) {
        case 'afterSubmit':
            return displayValidityAfterSubmit;
        case 'afterEdit':
            return displayValidityAfterEdit;
    }
}

export const displayValidityAfterEdit: GetShowFieldValidity = ({
    isValid,
    hasChange,
    isTyping,
}) => {
    if (!hasChange) return false;
    else if (isTyping) return false;
    else return isValid === false;
};

export const displayValidityAfterSubmit: GetShowFieldValidity = ({
    isValid,
    isTyping,
    hasSubmit,
}) => {
    if (!hasSubmit) return false;
    else if (isTyping) return false;
    else return isValid === false;
};
