import goldMedal from '@assets/images/gold-medal.svg';
import partnershipsHandShake from '@assets/images/handshake.svg';
import smartphone from '@assets/images/smartphone.svg';

import { Trans, useTranslation } from 'react-i18next';

import { Grid, Paper } from '@mui/material';
// Material UI
import Button from '@mui/material/Button';

import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';

import { useProductSearchFeedStore } from '@components/advertiser/feeds/feedsStore/useProductSearchFeedsStore';
import { FormPageLayout, FormPageTitle } from '@components/generic/Form';
import PageTabTitle from '@components/generic/PageTabTitle';

const EmptyFeedsLanding = () => {
    const { t } = useTranslation();

    const { openProductSearchFeedPanel: openPanel } = useProductSearchFeedStore();

    const feedInformations = [
        {
            title: t('feed_select_feature_partnerships'),
            description: t('feed_select_feature_partnerships_description'),
            icon: partnershipsHandShake,
        },
        {
            title: t('feed_select_feature_topProductSelling'),
            description: t('feed_select_feature_topProductSelling_description'),
            icon: goldMedal,
        },
        {
            title: t('feed_select_feature_productSearch'),
            description: t('feed_select_feature_productSearch_description'),
            icon: smartphone,
        },
    ];

    return (
        <FormPageLayout>
            <PageTabTitle>{t('feeds_header_title')}</PageTabTitle>
            <FormPageTitle>{t('feeds_header_title')}</FormPageTitle>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <div style={styles.subTitles}>
                        {t('feed_select_feature_what_is_product_feed')}
                    </div>
                </Grid>
                <Grid item md={9}>
                    <Paper sx={styles.infos}>
                        <Trans i18nKey='feeds_advertiser_emptyFeeds_infos' />
                    </Paper>
                </Grid>
                <Grid item md={3}>
                    <Paper sx={styles.xmlCase}>
                        <div style={styles.xmlText}>{t('feed_select_feature_xml')}</div>
                        <FileOpenOutlinedIcon sx={{ fontSize: '4rem' }} />
                    </Paper>
                </Grid>
                <Grid item md={12}>
                    <div style={styles.subTitles}>{t('feed_select_feature_what_can_i_do')}</div>
                </Grid>
                {feedInformations.map((feedInformation) => (
                    <Grid item md={4} key={feedInformation.title}>
                        <Paper sx={styles.paperInfos}>
                            <div style={styles.paperTitle}>{feedInformation.title}</div>
                            <div style={styles.paperContainer}>
                                <img src={feedInformation.icon} style={styles.paperImage} />
                                <div>{feedInformation.description}</div>
                            </div>
                        </Paper>
                    </Grid>
                ))}
                <Grid item md={12} sx={styles.button}>
                    <Button color='primary' variant='contained' onClick={() => openPanel()}>
                        {t('feeds_advertiser_emptyFeeds_button_create')}
                    </Button>
                </Grid>
            </Grid>
        </FormPageLayout>
    );
};

export const styles = {
    infos: {
        padding: '2rem',
        height: '100%',
        textAlign: 'justify',
    },
    xmlCase: {
        padding: '2rem',
        height: '100%',
        backgroundSize: '100% 100%',
        backgroundPosition:
            '0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px',
        backgroundImage:
            'radial-gradient(70% 53% at 36% 76%, #0459ADFF 0%, #073AFF00 100%),radial-gradient(42% 53% at 15% 94%, #F36CEEF5 7%, #073AFF00 100%),radial-gradient(42% 53% at 34% 72%, #4EB5FFFF 7%, #073AFF00 100%),radial-gradient(18% 28% at 35% 87%, #FFFFFFFF 7%, #073AFF00 100%),radial-gradient(31% 43% at 7% 98%, #F36CEEF5 24%, #073AFF00 100%),radial-gradient(21% 37% at 72% 23%, #9A6CF3F5 24%, #073AFF00 100%),radial-gradient(35% 56% at 91% 74%, #8A4FFFF5 9%, #073AFF00 100%),radial-gradient(74% 86% at 67% 38%, #8A4FFFF5 24%, #073AFF00 100%),linear-gradient(125deg, #4EB5FFFF 1%, #0054A8FF 100%)',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    xmlText: { fontWeight: 900, fontSize: '1.2rem', marginRight: '1rem' },
    subTitles: { fontSize: '1.5rem' },
    paperInfos: {
        padding: '2rem',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '1rem',
    },
    paperContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem',
    },
    paperTitle: { fontWeight: 'bold', fontSize: '1.2rem' },
    paperImage: { width: '4rem' },
    button: { textAlign: 'center', marginTop: '1rem' },
} as const satisfies Record<string, React.CSSProperties>;

export default EmptyFeedsLanding;
