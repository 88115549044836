import instagramLogo from '@assets/images/instagram-logo.svg';

import { ReactNode } from 'react';
import { cloneDeep } from 'lodash';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import EmailIcon from '@mui/icons-material/Email';

import { authProviders } from '../AppConfig';
import { UserType } from './getSharedVar';
import { getAccessTokenPayload, hasTokenExpired } from './jwt';

export { authProviders } from '../AppConfig';

export type AuthProvider = (typeof authProviders)[number];

export const authMethods = ['email', ...authProviders] as const;
export type AuthMethod = (typeof authMethods)[number];

type OAuthRequesterInfo = {
    page: string; // url path + query string
    userType: Exclude<UserType, 'admin'>;
};

const oAuthRequesterStorageKey = 'oAuth/requester';

export const oAuthRequesterStorage = {
    set(info: OAuthRequesterInfo) {
        localStorage.setItem(oAuthRequesterStorageKey, JSON.stringify(info));
    },

    get(): OAuthRequesterInfo | undefined {
        const info = localStorage.getItem(oAuthRequesterStorageKey);
        return info ? JSON.parse(info) : undefined;
    },
};

export type OauthStore = Partial<
    Record<
        AuthProvider,
        {
            code?: string;
            accessToken?: string;
        }
    >
> & {
    storeCode: (provider: AuthProvider, code: string) => void;
    storeAccessToken: (provider: AuthProvider, accessToken: string) => void;
    clear: (provider: AuthProvider) => void;
    clearAll: () => void;
};

export const useOauthStore = create<OauthStore>()(
    persist(
        (set) => ({
            storeCode: (provider, code) =>
                set((state) => ({
                    ...state,
                    [provider]: {
                        ...state[provider],
                        code,
                    },
                })),

            storeAccessToken: (provider, accessToken) =>
                set((state) => ({
                    ...state,
                    [provider]: {
                        ...state[provider],
                        accessToken,
                    },
                })),

            clear: (provider) =>
                set((state) => {
                    const newState = { ...state };
                    delete newState[provider];
                    return newState;
                }, true),

            clearAll: () => set({}, true),
        }),
        {
            name: 'oauth',
            storage: createJSONStorage(() => localStorage),
            version: 1,
        },
    ),
);

migrateOldAuthStoreToNewOne();
function migrateOldAuthStoreToNewOne() {
    for (const provider of authProviders) {
        const key = `oAuth/accessToken/${provider}`;
        const oldStoreAccessToken = localStorage.getItem(key);
        const newStoreAccessToken = useOauthStore.getState()[provider]?.accessToken;
        if (oldStoreAccessToken) {
            if (newStoreAccessToken == null) {
                useOauthStore.getState().storeAccessToken(provider, oldStoreAccessToken);
            }
            localStorage.removeItem(key);
        }
    }
}

export const AuthIcons: Record<AuthMethod, ReactNode> = {
    email: <EmailIcon />,
    instagram: <img src={instagramLogo} alt='Instagram' width={24} height={24} />,
};

export const getRedirectUrl = (authProvider: AuthProvider) =>
    `${window.location.origin}/en/oauth/callback/${authProvider}`;

export const oauthUrls: Record<AuthProvider, string> = {
    instagram: (() => {
        const url = new URL('https://www.instagram.com/oauth/authorize');
        url.searchParams.set('client_id', CONFIG.oauth.instagram.appId);
        url.searchParams.set('redirect_uri', getRedirectUrl('instagram'));
        url.searchParams.set('response_type', 'code');
        url.searchParams.set('scope', 'business_basic');
        url.hash = 'weblink'; // force web link for ios, avoid redirect to instagram app
        return url.toString();
    })(),
};

/** A generic for all third party user data */
export type UserThirdParty = {
    source: AuthProvider;
    id: string;
    name: string;
    username: string;
    profile_picture_url: string;
    biography: string;
    website: string;
    followers_count: number;
};

type ThirdPartyUserData = {
    data: Partial<Record<AuthProvider, UserThirdParty>>;
    save: (provider: AuthProvider, data: UserThirdParty) => void;
    get: (provider: AuthProvider) => UserThirdParty | undefined;
    remove: (provider: AuthProvider) => void;
    removeAll: () => void;
};

export const useThirdPartyUserDataStore = create<ThirdPartyUserData>((set, get) => ({
    data: {},
    save: (provider, data) => set((prev) => ({ data: { ...prev.data, [provider]: data } })),
    get: (provider) => get().data[provider],
    remove: (provider) => {
        set((prev) => {
            const next = cloneDeep(prev.data);
            delete next[provider];
            return { data: next };
        });
    },
    removeAll: () => set({ data: {} }),
}));

export type AuthStatus = 'none' | 'partial' | 'full';

export function getAuthStatus(token: string | undefined): AuthStatus {
    if (token == null || hasTokenExpired(token)) {
        return 'none';
    }
    return getAccessTokenPayload(token).requireMfa === true ? 'partial' : 'full';
}
