import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

import { getUserTypeFromTokens, removeAllJwt } from '@libs/jwt';

import EventViewLayout, { Actions, Illustration, Subtitle, Title } from '../../generic/EventView';
import illustrationNotLogged from './choice.svg';

const AlreadyLoggedPage = () => {
    const { t, i18n } = useTranslation();
    const userType = getUserTypeFromTokens();

    return (
        <EventViewLayout>
            <Helmet>{t('AlreadyLoggedPage_title')}</Helmet>
            <Illustration src={illustrationNotLogged} />
            <Title>{t('AlreadyLoggedPage_title')}</Title>
            <Subtitle>{t('AlreadyLoggedPage_subtitle')}</Subtitle>
            <Actions>
                <Button
                    variant='contained'
                    color='primary'
                    component={Link}
                    to={`/${i18n.language}/${userType}`}
                >
                    {t('generic_goToHome')}
                </Button>
                <Button color='primary' onClick={() => removeAllJwt()} className='mt-2'>
                    {t('AlreadyLoggedPage_continue')}
                </Button>
            </Actions>
        </EventViewLayout>
    );
};

export default AlreadyLoggedPage;
