import { addFlash } from '@actions/common';
import _ from 'lodash';
import { AdvertiserPaymentEarningState } from 'reducers/reducer_advertiser_payments';

import {
    formatMomentForApi,
    getExplicitPeriodFromPeriodRef,
    PeriodRef,
} from '@components/generic/DateRangePicker';

import { downloadBlob } from '@libs/fileHelper';
import { getCurrentProgramId } from '@libs/getSharedVar';
import { getJwt, getJwtAdmin } from '@libs/jwt';
import { AppDispatch } from '@libs/reduxHooks';
import { MongoId } from '@libs/types';

import { mergeInvoices } from '@api/admin/mergeInvoices';
import { ApiError, getAPISubUrlForAdmin, getAPISubUrlForAdvertiser } from '@api/common/utils';

export const INIT_PROGRAM = 'INIT_PROGRAM';

export const ADVERTISER_PAYMENT_LOADING = 'ADVERTISER_PAYMENT_LOADING';
export const ADVERTISER_PAYMENT_EARNINGS_LOADING = 'ADVERTISER_PAYMENT_EARNINGS_LOADING';

export const UPDATE_ADVERTISER_PAYMENTS_CRITERIA = 'UPDATE_ADVERTISER_PAYMENTS_CRITERIA';
export const UPDATE_ADVERTISER_EARNINGS_CRITERIA = 'UPDATE_ADVERTISER_EARNINGS_CRITERIA';

export const FETCH_ADVERTISER_PAYMENTS_DATA = 'FETCH_ADVERTISER_PAYMENTS_DATA';
export const FETCH_ADVERTISER_PAYMENTS_DATA_SUCCESS = 'FETCH_ADVERTISER_PAYMENTS_DATA_SUCCESS';
export const FETCH_ADVERTISER_PAYMENTS_DATA_FAILURE = 'FETCH_ADVERTISER_PAYMENTS_DATA_FAILURE';

export const FETCH_ADVERTISER_PAYMENT_REQUEST = 'FETCH_ADVERTISER_PAYMENT_REQUEST';
export const FETCH_ADVERTISER_PAYMENT_REQUEST_SUCCESS = 'FETCH_ADVERTISER_PAYMENT_REQUEST_SUCCESS';
export const FETCH_ADVERTISER_PAYMENT_REQUEST_FAILURE = 'FETCH_ADVERTISER_PAYMENT_REQUEST_FAILURE';

export const FETCH_ADVERTISER_EARNINGS_DATA = 'FETCH_ADVERTISER_EARNINGS_DATA';
export const FETCH_ADVERTISER_EARNINGS_DATA_SUCCESS = 'FETCH_ADVERTISER_EARNINGS_DATA_SUCCESS';
export const FETCH_ADVERTISER_EARNINGS_DATA_FAILURE = 'FETCH_ADVERTISER_EARNINGS_DATA_FAILURE';

export const OPEN_INVOICE = 'OPEN_INVOICE';
export const OPEN_INVOICE_SUCCESS = 'OPEN_INVOICE_SUCCESS';
export const OPEN_INVOICE_FAILURE = 'OPEN_INVOICE_FAILURE';

export const ADMIN_MERGE_INVOICES_PREVIEW = 'ADMIN_MERGE_INVOICES_PREVIEW';
export const ADMIN_MERGE_INVOICES_PREVIEW_SUCCESS = 'ADMIN_MERGE_INVOICES_PREVIEW_SUCCESS';
export const ADMIN_MERGE_INVOICES_PREVIEW_FAILURE = 'ADMIN_MERGE_INVOICES_PREVIEW_FAILURE';

export const ADMIN_MERGE_INVOICES = 'ADMIN_MERGE_INVOICES';
export const ADMIN_MERGE_INVOICES_SUCCESS = 'ADMIN_MERGE_INVOICES_SUCCESS';
export const ADMIN_MERGE_INVOICES_FAILURE = 'ADMIN_MERGE_INVOICES_FAILURE';

export const DELETE_INVOICE = 'DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAILURE = 'DELETE_INVOICE_FAILURE';

export const ADD_INVOICE = 'ADD_INVOICE';
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS';
export const ADD_INVOICE_FAILURE = 'ADD_INVOICE_FAILURE';

export const UPDATE_PAYMENT_REQUEST_STATUS = 'UPDATE_PAYMENT_REQUEST_STATUS';
export const UPDATE_PAYMENT_REQUEST_STATUS_SUCCESS = 'UPDATE_PAYMENT_REQUEST_STATUS_SUCCESS';
export const UPDATE_PAYMENT_REQUEST_STATUS_FAILURE = 'UPDATE_PAYMENT_REQUEST_STATUS_FAILURE';

export const REFUSE_PAYMENT_REQUEST = 'REFUSE_PAYMENT_REQUEST';
export const REFUSE_PAYMENT_REQUEST_SUCCESS = 'REFUSE_PAYMENT_REQUEST_SUCCESS';
export const REFUSE_PAYMENT_REQUEST_FAILURE = 'REFUSE_PAYMENT_REQUEST_FAILURE';

export const UPDATE_ADMIN_TOGGLED_PAYMENT_REQUEST_IDS = 'UPDATE_ADMIN_TOGGLED_PAYMENT_REQUEST_IDS';

export const OPEN_REFUSE_PAYMENT_REQUEST_DIALOG = 'OPEN_REFUSE_PAYMENT_REQUEST_DIALOG';
export const OPEN_DELETE_CUSTOM_INVOICE_DIALOG = 'OPEN_DELETE_CUSTOM_INVOICE_DIALOG';
export const OPEN_ADMIN_CONFIRM_MERGE_DIALOG = 'OPEN_ADMIN_CONFIRM_MERGE_DIALOG';

export const CLOSE_ALL_DIALOGS = 'CLOSE_ALL_DIALOGS';

export const VERIFY_ADVERTISER = 'VERIFY_ADVERTISER';

export function initProgram(program) {
    return {
        type: INIT_PROGRAM,
        program: program,
    };
}

export function advertiserPaymentLoading() {
    return {
        type: ADVERTISER_PAYMENT_LOADING,
    };
}

export function advertiserPaymentEarningsLoading() {
    return {
        type: ADVERTISER_PAYMENT_EARNINGS_LOADING,
    };
}

export function updateAdvertiserPaymentsCriteria(queryCriteria) {
    return {
        type: UPDATE_ADVERTISER_PAYMENTS_CRITERIA,
        queryCriteria: queryCriteria,
    };
}

export function updateAdvertiserEarningsCriteria(queryCriteria) {
    return {
        type: UPDATE_ADVERTISER_EARNINGS_CRITERIA,
        queryCriteria: queryCriteria,
    };
}

export function fetchAdvertiserPaymentsData(
    queryCriteria: AdvertiserPaymentEarningState['payments']['queryCriteria'],
) {
    const { from, to } = getExplicitPeriodFromPeriodRef(queryCriteria.dateRange as PeriodRef);
    const bodyObj = {
        program: queryCriteria.program,
        currency: queryCriteria.currency,
        from: formatMomentForApi(from),
        to: formatMomentForApi(to),
    };
    if (!_.isEmpty(queryCriteria.partnership)) {
        bodyObj.partnership = queryCriteria.partnership;
    }
    if (!_.isEmpty(queryCriteria.status)) {
        bodyObj.status = queryCriteria.status;
    }
    if (!_.isEmpty(queryCriteria.search)) {
        bodyObj.filter = queryCriteria.search;
    }
    const promise = fetch(getAPISubUrlForAdvertiser() + '/paymentRequests.list', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: FETCH_ADVERTISER_PAYMENTS_DATA,
        payload: promise,
    };
}

export function fetchAdvertiserPaymentsDataSuccess(data) {
    return {
        type: FETCH_ADVERTISER_PAYMENTS_DATA_SUCCESS,
        payload: data,
    };
}

export function fetchAdvertiserPaymentsDataFailure(error) {
    return {
        type: FETCH_ADVERTISER_PAYMENTS_DATA_FAILURE,
        payload: error,
    };
}

export function fetchAdvertiserPaymentRequest() {
    return {
        type: FETCH_ADVERTISER_PAYMENT_REQUEST,
    };
}

export function fetchAdvertiserPaymentRequestSuccess(data) {
    return {
        type: FETCH_ADVERTISER_PAYMENT_REQUEST_SUCCESS,
        payload: data,
    };
}

export function fetchAdvertiserPaymentRequestFailure(error) {
    return {
        type: FETCH_ADVERTISER_PAYMENT_REQUEST_FAILURE,
        payload: error,
    };
}

export function fetchAdvertiserPaymentsDataThunk(
    queryCriteria: AdvertiserPaymentEarningState['payments']['queryCriteria'],
) {
    return async (dispatch: AppDispatch) => {
        let statusCode: number;
        dispatch(advertiserPaymentLoading());
        dispatch(fetchAdvertiserPaymentsData(queryCriteria))
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload.json();
            })
            .then(function (result) {
                if (statusCode !== 200) {
                    dispatch(fetchAdvertiserPaymentsDataFailure(statusCode));
                } else {
                    dispatch(fetchAdvertiserPaymentsDataSuccess(result));
                }
            });
    };
}

export function fetchAdvertiserEarningsDataThunk(
    queryCriteria: AdvertiserPaymentEarningState['earnings']['queryCriteria'],
) {
    return async (dispatch: AppDispatch) => {
        let statusCode: number;
        // Temporary fix because program ID was set incorrectly (global state mutation)
        queryCriteria.program = getCurrentProgramId();
        dispatch(advertiserPaymentEarningsLoading());
        dispatch(fetchAdvertiserEarningsData(queryCriteria))
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload.json();
            })
            .then(function (result) {
                if (statusCode !== 200) {
                    dispatch(fetchAdvertiserEarningsDataFailure(statusCode));
                } else {
                    dispatch(fetchAdvertiserEarningsDataSuccess(result, queryCriteria));
                }
            });
    };
}

export function fetchAdvertiserEarningsData(
    queryCriteria: AdvertiserPaymentEarningState['earnings']['queryCriteria'],
) {
    const periodRef = queryCriteria.dateRange as PeriodRef;
    const { from, to } = getExplicitPeriodFromPeriodRef(periodRef);
    const bodyObj = {
        program: queryCriteria.program,
        currency: queryCriteria.currency,
        from: formatMomentForApi(from),
        to: formatMomentForApi(to),
    };
    if (!_.isEmpty(queryCriteria.partnership)) {
        bodyObj.partnership = queryCriteria.partnership;
    }
    if (!_.isEmpty(queryCriteria.status)) {
        bodyObj.status = queryCriteria.status;
    }
    if (!_.isEmpty(queryCriteria.search)) {
        bodyObj.filter = queryCriteria.search;
    }
    const promise = fetch(getAPISubUrlForAdvertiser() + '/affiliateEarnings.list', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: FETCH_ADVERTISER_EARNINGS_DATA,
        payload: promise,
    };
}

export function fetchAdvertiserEarningsDataSuccess(
    data,
    queryCriteria: AdvertiserPaymentEarningState['earnings']['queryCriteria'],
) {
    const currency = queryCriteria.currency;
    // We filter earnings to exclude commissions zero
    const positiveEarnings = _.filter(data, function (earningObject) {
        return (
            earningObject.commissions[currency] && earningObject.commissions[currency].total !== 0
        );
    });
    return {
        type: FETCH_ADVERTISER_EARNINGS_DATA_SUCCESS,
        payload: positiveEarnings,
    };
}

export function fetchAdvertiserEarningsDataFailure(error) {
    return {
        type: FETCH_ADVERTISER_EARNINGS_DATA_FAILURE,
        payload: error,
    };
}

export function openInvoiceThunk(paymentRequestId: MongoId | MongoId[], paymentRequestIdentifier?) {
    return (dispatch: AppDispatch) => {
        let statusCode;
        dispatch(openInvoice(paymentRequestId))
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload.blob();
            })
            .then(function (result) {
                if (statusCode !== 200) {
                    dispatch(openInvoiceFailure(statusCode));
                } else {
                    dispatch(openInvoiceSuccess(result, paymentRequestIdentifier));
                }
            });
    };
}

export function openInvoice(paymentRequestId) {
    let ids = [];
    if (typeof paymentRequestId === 'string') {
        ids.push(paymentRequestId);
    } else {
        ids = paymentRequestId;
    }
    const bodyObj = {
        id: ids,
    };
    const promise = fetch(getAPISubUrlForAdvertiser() + '/paymentRequests/invoices.get', {
        method: 'POST',
        responseType: 'blob',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: OPEN_INVOICE,
        payload: promise,
    };
}

export function openInvoiceSuccess(data, identifier?: string) {
    const filename =
        data.type === 'application/pdf' ? 'invoice_' + identifier + '.pdf' : 'invoices.zip';
    downloadBlob(data, filename);
    return {
        type: OPEN_INVOICE_SUCCESS,
        payload: data,
    };
}

export function openInvoiceFailure(error) {
    return {
        type: OPEN_INVOICE_FAILURE,
        payload: error,
    };
}

export function adminMergeInvoicesPreviewThunk(
    paymentRequestIds,
    advertiserId,
    currency,
    programId,
) {
    return (dispatch: AppDispatch) => {
        let statusCode;
        dispatch(adminMergeInvoicesPreview(paymentRequestIds, advertiserId, currency, programId))
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload.json();
            })
            .then(function (result) {
                if (statusCode !== 200) {
                    dispatch(adminMergeInvoicesPreviewFailure(statusCode));
                } else {
                    dispatch(adminMergeInvoicesPreviewSuccess(result));
                }
            });
    };
}

export function adminMergeInvoicesPreview(paymentRequestIds, advertiserId, currency, programId) {
    const bodyObj = {
        id: paymentRequestIds,
        program: programId,
        advertiser: advertiserId,
        currency: currency,
    };

    const adminJwt = getJwtAdmin();
    const promise = fetch(getAPISubUrlForAdmin() + '/paymentRequests/invoices.preview', {
        method: 'POST',
        headers: {
            Authorization: adminJwt,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: ADMIN_MERGE_INVOICES_PREVIEW,
        payload: promise,
    };
}

export function adminMergeInvoicesPreviewSuccess(data) {
    return {
        type: ADMIN_MERGE_INVOICES_PREVIEW_SUCCESS,
        payload: data,
    };
}

export function adminMergeInvoicesPreviewFailure(error) {
    return {
        type: ADMIN_MERGE_INVOICES_PREVIEW_FAILURE,
        payload: error,
    };
}

export function adminMergeInvoicesThunk(
    paymentRequestIds: string[],
    advertiserId: string,
    currency: string,
    programId: string,
    allPayments: Payment[],
    commissionsPaymentsRemarks: string | null | undefined,
    remarks: string | null | undefined,
) {
    return async (dispatch: AppDispatch) => {
        dispatch({ type: ADMIN_MERGE_INVOICES });
        try {
            const blob = await mergeInvoices(
                advertiserId,
                programId,
                paymentRequestIds,
                currency,
                commissionsPaymentsRemarks,
                remarks,
            );
            downloadBlob(blob, 'invoice.zip');
            const updatedPayments = updatePayments(paymentRequestIds, allPayments);
            dispatch({
                type: ADMIN_MERGE_INVOICES_SUCCESS,
                payload: updatedPayments,
            });
            dispatch(closeAllDialogs());
        } catch (error) {
            dispatch({
                type: ADMIN_MERGE_INVOICES_FAILURE,
                payload: error,
            });
            window.addFlash('error', (error as ApiError).message);
        }
    };
}

type Payment = Record<string, unknown>;

function updatePayments(mergedPaymentRequestIds: string[], allPayments: Payment[]): Payment[] {
    return allPayments
        .filter((payment) => mergedPaymentRequestIds.includes(payment.paymentRequestId))
        .map((payment) => ({
            ...payment,
            invoiceMerged: 'dummy id',
            status: 'accepted',
            updatedOn: new Date().toISOString(),
        }));
}

export function deleteInvoiceThunk(paymentRequestId, allPayments) {
    return (dispatch: AppDispatch) => {
        let statusCode;
        dispatch(deleteInvoice(paymentRequestId))
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload;
            })
            .then(function (result) {
                if (statusCode !== 200) {
                    dispatch(deleteInvoiceFailure(statusCode));
                } else {
                    dispatch(deleteInvoiceSuccess(paymentRequestId, allPayments));
                }
            });
    };
}

export function deleteInvoice(paymentRequestId) {
    const bodyObj = {
        id: paymentRequestId,
    };
    const promise = fetch(getAPISubUrlForAdvertiser() + '/paymentRequests/invoices.delete', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: DELETE_INVOICE,
        payload: promise,
    };
}

export function deleteInvoiceSuccess(deletedId, allPayments) {
    const updatedPayments = [];

    _.each(allPayments, function (paymentData) {
        if (paymentData.paymentRequestId == deletedId) {
            paymentData.invoice = null;
        }
        updatedPayments.push(paymentData);
    });

    return {
        type: DELETE_INVOICE_SUCCESS,
        payload: updatedPayments,
    };
}

export function deleteInvoiceFailure(error) {
    return {
        type: DELETE_INVOICE_FAILURE,
        payload: error,
    };
}

export function addInvoiceThunk(paymentRequestId, file, allPayments) {
    return (dispatch: AppDispatch) => {
        let statusCode;
        dispatch(addInvoice(paymentRequestId, file))
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload.json();
            })
            .then(function (result) {
                if (statusCode === 200 || statusCode === 201) {
                    dispatch(addFlash('success', _t('payments_action_add_custom_flash')));
                    dispatch(addInvoiceSuccess(paymentRequestId, result.invoiceId, allPayments));
                } else {
                    let errMsg;
                    if (statusCode === 413) {
                        errMsg = _t('payments_action_add_err_tooLarge_custom_flash');
                    } else if (statusCode === 400) {
                        errMsg = _t('payments_action_add_err_file_custom_flash');
                    } else {
                        errMsg = _t('payments_action_add_err_default_custom_flash');
                    }
                    dispatch(addFlash('error', errMsg));
                    dispatch(addInvoiceFailure(statusCode));
                }
            });
    };
}

export function addInvoice(paymentRequestId, file) {
    const form = new FormData();
    form.append('file', file);
    form.append('size', file.size);
    form.append('id', paymentRequestId);

    const promise = fetch(getAPISubUrlForAdvertiser() + '/paymentRequests/invoices.add', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
        },
        body: form,
    });

    return {
        type: ADD_INVOICE,
        payload: promise,
    };
}

export function addInvoiceSuccess(paymentRequestId, invoiceId, allPayments) {
    const updatedPayments = [];

    _.each(allPayments, function (paymentData) {
        if (paymentData.paymentRequestId == paymentRequestId) {
            paymentData.invoice = invoiceId;
        }
        updatedPayments.push(paymentData);
    });

    return {
        type: ADD_INVOICE_SUCCESS,
        payload: updatedPayments,
    };
}

export function addInvoiceFailure(error) {
    return {
        type: ADD_INVOICE_FAILURE,
        payload: error,
    };
}

export function updatePaymentRequestStatusThunk(
    paymentRequestId,
    status,
    allPayments,
    queryCriteria,
) {
    return (dispatch: AppDispatch) => {
        let statusCode;
        dispatch(updatePaymentRequestStatus(paymentRequestId, status))
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload;
            })
            .then(function (result) {
                if (statusCode !== 200) {
                    dispatch(updatePaymentRequestStatusFailure(statusCode));
                } else {
                    dispatch(
                        updatePaymentRequestStatusSuccess(paymentRequestId, status, allPayments),
                    );
                    dispatch(advertiserPaymentLoading());
                    dispatch(fetchAdvertiserPaymentsData(queryCriteria))
                        .then(function (response) {
                            statusCode = response.payload.status;
                            return response.payload.json();
                        })
                        .then(function (result) {
                            if (statusCode !== 200) {
                                dispatch(fetchAdvertiserPaymentsDataFailure(statusCode));
                            } else {
                                dispatch(fetchAdvertiserPaymentsDataSuccess(result));
                            }
                        });
                }
            });
    };
}

export function updatePaymentRequestStatus(paymentRequestId, status) {
    const bodyObj = {
        id: paymentRequestId,
    };

    const statusEndpoints = {
        accepted: 'accept',
        paid: 'pay',
        scheduled: 'schedule',
    };

    const promise = fetch(
        getAPISubUrlForAdvertiser() + '/paymentRequests.' + statusEndpoints[status],
        {
            method: 'POST',
            headers: {
                Authorization: getJwt(),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(bodyObj),
        },
    );

    return {
        type: UPDATE_PAYMENT_REQUEST_STATUS,
        payload: promise,
    };
}

export function updatePaymentRequestStatusSuccess(paymentRequestId, status, allPayments) {
    const updatedPayments = [];

    _.each(allPayments, function (paymentData) {
        if (paymentData.paymentRequestId == paymentRequestId) {
            paymentData.status = status;
            paymentData.updatedOn = new Date().toISOString();
        }
        updatedPayments.push(paymentData);
    });

    return {
        type: UPDATE_PAYMENT_REQUEST_STATUS_SUCCESS,
        payload: updatedPayments,
        status: status,
    };
}

export function updatePaymentRequestStatusFailure(error) {
    return {
        type: UPDATE_PAYMENT_REQUEST_STATUS_FAILURE,
        payload: error,
    };
}

export function refusePaymentRequestThunk(paymentRequestId, reason, allPayments, queryCriteria) {
    return (dispatch: AppDispatch) => {
        let statusCode;
        dispatch(refusePaymentRequest(paymentRequestId, reason))
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload;
            })
            .then(function (result) {
                if (statusCode !== 200) {
                    dispatch(refusePaymentRequestFailure(statusCode));
                } else {
                    dispatch(refusePaymentRequestSuccess(paymentRequestId, reason, allPayments));
                    dispatch(closeAllDialogs());
                    dispatch(advertiserPaymentLoading());
                    dispatch(fetchAdvertiserPaymentsData(queryCriteria))
                        .then(function (response) {
                            statusCode = response.payload.status;
                            return response.payload.json();
                        })
                        .then(function (result) {
                            if (statusCode !== 200) {
                                dispatch(fetchAdvertiserPaymentsDataFailure(statusCode));
                            } else {
                                dispatch(fetchAdvertiserPaymentsDataSuccess(result));
                            }
                        });
                }
            });
    };
}

export function refusePaymentRequest(paymentRequestId, reason) {
    const bodyObj = {
        id: paymentRequestId,
        reason: reason,
    };

    const promise = fetch(getAPISubUrlForAdvertiser() + '/paymentRequests.refuse', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: REFUSE_PAYMENT_REQUEST,
        payload: promise,
    };
}

export function refusePaymentRequestSuccess(paymentRequestId, reason, allPayments) {
    const updatedPayments = [];

    _.each(allPayments, function (paymentData) {
        if (paymentData.paymentRequestId == paymentRequestId) {
            paymentData.status = 'refused';
            paymentData.refusedReason = reason;
            paymentData.updatedOn = new Date().toISOString();
        }
        updatedPayments.push(paymentData);
    });

    return {
        type: REFUSE_PAYMENT_REQUEST_SUCCESS,
        payload: updatedPayments,
        refusedReason: reason,
    };
}

export function refusePaymentRequestFailure(error) {
    return {
        type: REFUSE_PAYMENT_REQUEST_FAILURE,
        payload: error,
    };
}

export function updateAdminToggledPaymentRequestIds(toggledPaymentRequestIds, toggledIdentifiers) {
    return {
        type: UPDATE_ADMIN_TOGGLED_PAYMENT_REQUEST_IDS,
        paymentRequestIds: toggledPaymentRequestIds,
        identifiers: toggledIdentifiers,
    };
}

export function openRefusePaymentRequestDialog(paymentRequestId) {
    return {
        type: OPEN_REFUSE_PAYMENT_REQUEST_DIALOG,
        paymentRequestId: paymentRequestId,
    };
}

export function openDeleteCustomInvoiceDialog(paymentRequestId) {
    return {
        type: OPEN_DELETE_CUSTOM_INVOICE_DIALOG,
        paymentRequestId: paymentRequestId,
    };
}

export function openAdminConfirmMergeDialog() {
    return {
        type: OPEN_ADMIN_CONFIRM_MERGE_DIALOG,
    };
}

export function closeAllDialogs() {
    return {
        type: CLOSE_ALL_DIALOGS,
    };
}

export function verifyAdvertiser(advertiser) {
    let isVerified = true;

    const mandatoryFieldsAdvertiser = [
        'company',
        'address',
        'zipcode',
        'city',
        'country',
        'companyId',
    ];

    if (!advertiser.isVatFree) {
        mandatoryFieldsAdvertiser.push('companyVat');
    }

    mandatoryFieldsAdvertiser.forEach(function (field) {
        if (
            advertiser[field] === undefined ||
            advertiser[field] === null ||
            advertiser[field] === ''
        ) {
            isVerified = false;
        }
    });

    return {
        type: VERIFY_ADVERTISER,
        isVerified: isVerified,
    };
}
