// todo use react-route / context / redux to share these data
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createBrowserHistory, Location } from 'history';
import moment from 'moment';

import { User } from '@api/user/types';

export const emailSupport = 'support@affilae.com'; //move to config

//#region move to i18n or dedicated lib
export const localesSupported = ['fr', 'en', 'es'] as const;
export const localesTradSupportedUpper = localesSupported.map((locale) =>
    locale.toUpperCase(),
) as LocaleTradUpper[];
export type LocaleTrad = (typeof localesSupported)[number];
export type LocaleTradUpper = Uppercase<LocaleTrad>;
export const localeDefault: LocaleTrad = 'en';
// #endregion

export const skipTutorialStorageKey = 'skipTutorial';

//#region user Utility
/**
 * @throws when can't determine kind
 */
export function getUserType(user = getCurrentUser()): UserTypeNonAdmin {
    if (user.isAdvertiser) {
        return 'advertiser';
    } else if (user.isPublisher) {
        return 'publisher';
    } else {
        throw Error('Unknown user kind');
        // todo report error to sentry
    }
}

export function getDefaultUserType(user: User): UserType {
    return user.isAffilaeAdmin ? 'admin' : getUserType(user);
}

export function isBlockedByMail(user: User) {
    return (
        user.emailConfirmedAt == null &&
        moment().isAfter(moment.unix(user.emailUpdatedAt ?? user.createdAt).add(3, 'days'))
    );
}

export const userTypesNonAdmin = ['advertiser', 'publisher'] as const;
export type UserTypeNonAdmin = (typeof userTypesNonAdmin)[number];

export const userTypes = ['admin', ...userTypesNonAdmin] as const; // sort by jwt priority
export type UserType = (typeof userTypes)[number];

// #endregion

//#region parse Url
export function getUserTypeFromUrl(urlPath = window.location.pathname): UserType | undefined {
    const pathParts = urlPath.split('/').slice(1); // first part is empty because pathname start with a '/'
    if (pathParts.length >= 2) {
        const side = userTypes.find((side) => side === pathParts[1]);
        return side;
    }
    return undefined;
}

export function useUserTypeFromUrl() {
    const history = useHistory();
    const [userType, setUserType] = useState<UserType | undefined>(
        getUserTypeFromUrl(history.location.pathname),
    );

    useEffect(() => {
        return history.listen(handleUrlChange);

        function handleUrlChange(location: Location) {
            setUserType(getUserTypeFromUrl(location.pathname));
        }
    }, [history]);

    return userType;
}

/**
 * Return the string template followed by url path
 *
 * template syntax is the one used by react-router
 * https://ui.dev/react-router-v4-url-parameters/
 * https://reactrouter.com/web/api/Hooks/useparams
 *
 * @example
 * getPage('/fr/advertiser/55c86be6665e8865638b469f/payments/5f7c8254b856fa639968406b/details')
 * // return '/advertiser/:programId/payments/:paymentRequestId/details'
 */
export function getUrlTemplate(urlPath: string) {
    let template = urlPath;

    type GetParameter = (urlPath?: string) => string | undefined;
    const urlParametersGetters: [GetParameter, string][] = [
        [getLocale, 'local'],
        [getCurrentProgramId, 'programId'],
        [getCurrentAffiliateProfileId, 'affiliateProfileId'],
        [getCurrentPartnershipId, 'partnershipId'],
        [getCurrentPaymentRequestId, 'paymentRequestId'],
    ];

    const parameters = {};
    urlParametersGetters.forEach(([getParameter, parameterName]) => {
        const parameterValue = getParameter(urlPath);
        if (parameterValue !== undefined) {
            template = template.replace(parameterValue, `:${parameterName}`);
            parameters[parameterName] = parameterValue;
        }
    });

    return { template, parameters };
}

export function getPage(urlPath: string) {
    const { template } = getUrlTemplate(urlPath);
    const [_, locale, ...pagePart] = template.split('/');
    const page = `/${pagePart.join('/')}`;
    return page;
}

export function getCurrentProgramId(urlPath = window.location.pathname) {
    if (getUserTypeFromUrl() !== 'advertiser') return undefined;
    return getCurrentSubEntityId(urlPath);
}

export function getCurrentAffiliateProfileId(urlPath = window.location.pathname) {
    if (getUserTypeFromUrl() !== 'publisher') return undefined;
    return getCurrentSubEntityId(urlPath);
}

export function getCurrentSubEntityId(urlPath = window.location.pathname) {
    const pathParts = urlPath.split('/').slice(1); // first part is empty because pathname start with a '/'
    if (pathParts.length >= 3 && pathParts[2].length === 24) return pathParts[2];
    else return undefined;
}

export function getCurrentPartnershipId(urlPath = window.location.pathname) {
    const pathParts = urlPath.split('/').slice(1); // first part is empty because pathname start with a '/'
    if (
        pathParts.length >= 5 &&
        getCurrentProgramId(urlPath) !== undefined &&
        pathParts[3] === 'affiliates' &&
        pathParts[4].length === 24
    ) {
        return pathParts[4];
    } else {
        return undefined;
    }
}

export function getCurrentPaymentRequestId(urlPath = window.location.pathname) {
    const pathParts = urlPath.split('/').slice(1); // first part is empty because pathname start with a '/'
    if (
        pathParts.length >= 5 &&
        (getCurrentProgramId(urlPath) !== undefined ||
            getCurrentAffiliateProfileId(urlPath) !== undefined) &&
        pathParts[3] === 'payments' &&
        pathParts[4].length === 24
    ) {
        return pathParts[4];
    } else {
        return undefined;
    }
}

/** Prefer to get it from i18n hook */
export function getLocale(urlPath = window.location.pathname): LocaleTrad {
    return parseLocale(urlPath) ?? localeDefault;
}
export function parseLocale(urlPath = window.location.pathname): LocaleTrad | undefined {
    if (urlPath === '/') return undefined;
    const localeUrl = urlPath.split('/')[1] as LocaleTrad;
    return localesSupported.includes(localeUrl) ? localeUrl : undefined;
}
export interface ParamTypesLocale {
    locale: LocaleTrad;
}

export interface ParamTypes extends ParamTypesLocale {
    side: UserType;
}

export interface ParamTypesAdvertiser extends ParamTypes {
    programId: string;
}
export interface ParamTypesPublisher extends ParamTypes {
    affiliateProfileId: string;
}

//#endregion

let currentUser: User | undefined = undefined;
export function getCurrentUser(): User {
    if (currentUser == null) {
        throw new Error('Current user not loaded');
    }
    return currentUser;
}
export function setCurrentUser(user: User) {
    currentUser = user;
}

export const getLinkMarketPlace = (locale: LocaleTrad) => {
    const baseUrlWithLocale = CONFIG.website_url + `/${locale}`;
    switch (locale) {
        case 'fr':
            return baseUrlWithLocale + '/marketplace';
        case 'en':
            return baseUrlWithLocale + '/marketplace-' + locale;
        default:
            return baseUrlWithLocale + '/marketplace';
    }
};
export const getLinkMarketPlaceAffiliate = (locale: LocaleTrad) =>
    getLinkMarketPlace(locale) + '/affiliates';
export const getLinkMarketPlacePrograms = (locale: LocaleTrad) =>
    getLinkMarketPlace(locale) + '/programs';

export const history = createBrowserHistory();
